import useAuth from 'hooks/useAuth'
import useGlobal from 'hooks/useGlobal'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IUser } from 'types'

import Dropdown from 'components/Dropdown/Dropdown'
import RenderIfCan from 'components/Permissions/RenderIfCan'

const Root = styled.div`
  display: flex;
  margin: 1rem 0 1rem;
  justify-content: space-between;
`
const MenuItem = styled.div`
  a {
    padding: 0.25rem 0.5rem;
    display: block;
    color: var(--color-gray-600);
    &:hover {
      background-color: var(--color-gray-100);
    }
  }
`
const NavbarItems = styled.div``
const Role = styled.span`
  background-color: var(--color-slate-200);
  padding: 0.25rem 0.5rem;
  border-radius: 0.4rem;
  color: var(--color-gray-700);
  font-size: 0.8rem;
  margin-left: 0.5rem;
`

interface Props {
  user: IUser
}

const Header: React.FC<Props> = ({ user }) => {
  const { logout } = useAuth()
  const { organisationMember } = useGlobal()

  const handleClickLogout = () => logout()

  return (
    <Root>
      <div>
        Welcome back {user.name} <Role>{organisationMember?.role}</Role>
      </div>
      <NavbarItems>
        <Dropdown label="Account" closeOnClick={true}>
          <MenuItem>
            <Link to={`/profile`}>Profile</Link>
          </MenuItem>
          <RenderIfCan action="manage-billing">
            <MenuItem>
              <Link to={`/billing`}>Billing</Link>
            </MenuItem>
          </RenderIfCan>
          <MenuItem>
            <Link onClick={handleClickLogout} to={''}>
              Logout
            </Link>
          </MenuItem>
        </Dropdown>
      </NavbarItems>
    </Root>
  )
}

export default Header
