import { RecursivePartial } from 'helperTypes'
import {
  IConnection,
  IConnectRequest,
  IFlow,
  IFlowNode,
  IFlowRun,
  IFlowWithLastRun,
  IIntegration,
  IInvite,
  ILoggedInUser,
  IMember,
  INodeData,
  INodeItemSchema,
  INodeType,
  IOrganisationMember,
  IPublicUser,
  IResultSet,
  ITeam,
  ITeamMember,
  IUser,
  IWorld,
  PaginatedResults
} from 'types'

import { callApi } from './transport'

// This is the currently logged in user, if null then no one is
// logged in
let currentUser: ILoggedInUser | undefined = undefined

export const setCurrentUser = (user: ILoggedInUser) => {
  currentUser = user
}

interface ICreateUser {
  name: string
  emailAddress: string
  password: string
}

export const createUser = (userData: ICreateUser) => callApi<IPublicUser>('POST', '/users/', { data: userData })

interface IAuthUser {
  emailAddress: string
  password: string
}

export const authUser = (userData: IAuthUser) => callApi<ILoggedInUser>('POST', '/users/auth/', { data: userData })

export const updateUser = (data: Record<string, unknown>) =>
  callApi<IUser>('PUT', '/users/', { user: currentUser, data })

export const getStripeBillingPortalUrl = () => callApi<{ url: string }>('GET', `/billing/`, { user: currentUser })

export const getWorld = () => callApi<IWorld>('GET', '/world/', { user: currentUser })

export const getOrganisationUsers = () => callApi<IMember[]>('GET', `/organisation/users/`, { user: currentUser })

export const getInvites = () => callApi<IInvite[]>('GET', `/organisation/invites/`, { user: currentUser })

export const createInvite = (values: any) =>
  callApi<IInvite>('POST', `/organisation/invite/`, { data: values, user: currentUser })

export const deleteInvite = (id: string) => callApi('DELETE', `/organisation/invite/${id}/`, { user: currentUser })

export const getTeams = () => callApi<ITeam[]>('GET', `/organisation/teams/`, { user: currentUser })

export const getTeamMembers = (team: ITeam) =>
  callApi<ITeamMember[]>('GET', `/team/${team.publicId}/members/`, { user: currentUser })

export const createTeam = (team: any) => callApi<ITeam>('POST', `/team/`, { data: team, user: currentUser })

export const getTeam = (id: string) => callApi<ITeam>('GET', `/team/${id}/`, { user: currentUser })

export const deleteTeamMember = (team: ITeam, userId: string) =>
  callApi('DELETE', `/team/${team.publicId}/members/${userId}/`, { user: currentUser })

export const addUserToTeam = (team: ITeam, email: string) =>
  callApi<ITeamMember>('POST', `/team/${team.publicId}/members/`, { user: currentUser, data: { email } })

export const verifyAccount = (email: string, code: string) =>
  callApi('POST', `/users/verify/`, {
    data: { emailAddress: email, code }
  })

export const sendVerificationCode = (email: string) =>
  callApi('POST', `/users/send-verification/`, {
    data: { emailAddress: email }
  })

export const patchOrganisationMember = (id: string, data: any) =>
  callApi<IOrganisationMember>('PATCH', `/organisation/member/${id}/`, { user: currentUser, data })

export const getFlows = () => callApi<PaginatedResults<IFlowWithLastRun>>('GET', `/flow/`, { user: currentUser })

export const getFlow = (id: string) => callApi<IFlow>('GET', `/flow/${id}/`, { user: currentUser })

export const patchNode = (flowNodeId: string, patchData: RecursivePartial<IFlowNode>) =>
  callApi<IFlowNode>('PATCH', `/node/${flowNodeId}/`, { data: patchData, user: currentUser })

export const runNode = (flowNodeId: string) =>
  callApi<IFlowNode>('POST', `/node/${flowNodeId}/run/`, { user: currentUser })

export const getNodeResultSet = (flowNodeId: string) =>
  callApi<INodeData>('GET', `/node/${flowNodeId}/data/`, { user: currentUser })

export const getIntegrations = () => callApi<IIntegration[]>('GET', `/integration/`, { user: currentUser })

export const getConnections = (params?: { kind?: string }) =>
  callApi<IConnection[]>('GET', `/connection/`, {
    user: currentUser,
    params: {
      kind: params?.kind
    }
  })

export const createFlow = (values: Pick<IFlow, 'name'>) =>
  callApi<IFlow>('POST', `/flow/`, {
    user: currentUser,
    data: {
      ...values
    }
  })

export const createNode = (flowId: string, values: Partial<IFlowNode>) =>
  callApi<IFlowNode>('POST', `/node/`, {
    user: currentUser,
    data: {
      ...values,
      flow: {
        publicId: flowId
      }
    }
  })

export const createNodeEdge = (flowId: string, sourceId: string, targetId: string) =>
  callApi<IFlow>('POST', `/flow/${flowId}/edge/`, {
    user: currentUser,
    data: {
      sourceId,
      targetId
    }
  })

export const deleteNode = (nodeId: string) => callApi('DELETE', `/node/${nodeId}/`, { user: currentUser })

export const deleteEdge = (edgeId: string) =>
  callApi<string>('DELETE', `/flow/edge/${edgeId}/`, {
    user: currentUser
  })

export const getIntegration = (integrationId: string) =>
  callApi<IIntegration>('GET', `/integration/${integrationId}/`, { user: currentUser })

export const createConnection = (integrationId: string, values: RecursivePartial<IConnection>) =>
  callApi<IConnection>('POST', `/connection/`, { user: currentUser, data: values })

export const getNodeTypes = () => callApi<PaginatedResults<INodeType>>('GET', `/node/types/`, { user: currentUser })

export const refreshConnection = (connectionId: string) =>
  callApi<IConnection>('POST', `/connection/${connectionId}/refresh/`, { user: currentUser })

export const runFlow = (flowId: string) => callApi<IFlowRun>('POST', `/flow/${flowId}/run/`, { user: currentUser })

type IGetFlowRunsRequestParams = {
  flowId?: string
  page: number
  size: number
}
export const getFlowRuns = (params: IGetFlowRunsRequestParams) =>
  callApi<PaginatedResults<IFlowRun>>('GET', `/flow-run/`, { user: currentUser, params: params })

export const kickstartOAuth = (integrationId: string, setupParams?: Record<string, unknown>) =>
  callApi<{ url: string }>('POST', `/integration/${integrationId}/:kickstart-oauth/`, {
    user: currentUser,
    data: {
      setupParams
    }
  })

export const completeOAuth = (code: string, state: string) =>
  callApi('POST', `/integration/:oauth-callback/`, {
    user: currentUser,
    data: {
      code,
      state
    }
  })

export const getNodeSchema = (flowNodeId: string) =>
  callApi<INodeItemSchema[]>('GET', `/node/${flowNodeId}/schema/`, { user: currentUser })

export const getNodeInputs = (flowNodeId: string) =>
  callApi<PaginatedResults<IResultSet>>('GET', `/node/${flowNodeId}/inputs/`, { user: currentUser })

export const deleteConnection = (connectionId: string) =>
  callApi('DELETE', `/connection/${connectionId}/`, { user: currentUser })

export const getNode = (nodeId: string) => callApi<IFlowNode>('GET', `/node/${nodeId}/`, { user: currentUser })

export const getConnectRequest = (id: string) => callApi<IConnectRequest>('GET', `/connect-request/${id}/`)

export const deleteWorkflow = (id: string) =>
  callApi('DELETE', `/flow/${id}/`, {
    user: currentUser
  })
