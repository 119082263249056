import useGraph from 'hooks/useGraph'
import useGraphV2 from 'hooks/useGraphV2'
import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import styled, { css, keyframes } from 'styled-components'
import { IFlowNode } from 'types'

import Controls from './Controls'

// https://web.dev/css-border-animations/
const rotate = keyframes`
100% {
		transform: rotate(1turn);
	}

`

const borderCss = css`
  overflow: hidden;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300),
      linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
    animation: ${rotate} 4s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 10px;
  }
`

const Root = styled.div`
  cursor: pointer;
  max-width: 100px;
`

type NodeBoxProps = {
  running: boolean
}
const NodeBox = styled.div<NodeBoxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--color-slate-50);
  width: 100px;
  height: 100px;
  box-shadow: var(--shadow-md);
  position: relative;

  .react-flow__handle {
    background: #ff0072;
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }

  ${(props) => (props.running ? borderCss : null)}
`
const Title = styled.div`
  text-align: center;
`

const NodeRoot: React.FC<NodeProps<IFlowNode> & { children: ReactElement }> = ({ data, children }) => {
  const { setActiveNode, deleteNode } = useGraph()
  const { isRunning } = useGraphV2((s) => ({ isRunning: s.isRunning, changes: s.nodeChanges }))

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault()
    setActiveNode({
      show: true,
      nodeId: data.publicId
    })
  }

  const handleDeleteClick = (evt: React.MouseEvent) => {
    evt.preventDefault()
    deleteNode(data)
  }

  return (
    <Root>
      <Title>{data.name}</Title>
      <NodeBox running={isRunning(data.publicId)}>{children}</NodeBox>
      <Controls onEditClick={handleClick} onDeleteClick={handleDeleteClick} />
    </Root>
  )
}

export default React.memo(NodeRoot)
