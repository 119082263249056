import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IUser } from 'types'
import { clearUserDataFromBrowser, saveAuthToBrowser } from 'utils/storage'

import * as api from 'services/api'

const _userAuthProvider = () => {
  const [user, setUser] = React.useState<IUser>()
  const navigate = useNavigate()

  const login = async (emailAddress: string, password: string) => {
    const data = await api.authUser({ emailAddress, password })
    setUser(data)
    saveAuthToBrowser(data)
    api.setCurrentUser(data)
    navigate('/')
  }

  const logout = () => {
    clearUserDataFromBrowser()
    setUser(undefined)
    navigate('/')
  }

  return { setUser, user, logout, login }
}

// Create a type from the return values of _useDataProvider
type AuthContextType = ReturnType<typeof _userAuthProvider>

// Main context provider
const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

type Props = {
  children?: React.ReactNode
}

// Provides data for a single spreadsheet component
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const value = _userAuthProvider()
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth can only be used within an Auth context')
  }

  return context
}

export default useAuth
