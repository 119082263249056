import useAuth from 'hooks/useAuth'
import React, { ReactElement } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { getAuthFromBrowser, pushOAuthRequest } from 'utils/storage'

import { setCurrentUser } from 'services/api'

interface AuthGuardProps {
  children: ReactElement
}

// If Oauth request push request into a local queue
const pushOAuthRequestsIfExists = () => {
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const state = params.get('state')

  if (!code || !state) {
    return
  }

  pushOAuthRequest(code, state)
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const [redirect, setRedirect] = React.useState(false)
  const cookieData = getAuthFromBrowser()
  const location = useLocation()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    // load user info from local storage
    if (cookieData && !user) {
      setUser(cookieData)
      setCurrentUser(cookieData)

      // if we're on the login page, but have authenticated then redirect to root page
      if (location.pathname === '/login') {
        navigate('/')
      }
    }

    // redirect to login page
    if (!user && !cookieData) {
      pushOAuthRequestsIfExists()
      setRedirect(true)
    }
  }, [cookieData, user])

  if (redirect) {
    return <Navigate to={'/login'} />
  }

  if (!user) {
    return null
  }

  return children
}

export default AuthGuard
