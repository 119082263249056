import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { IConnection, IFlowNode, IModelWithId, INodeItemSchema, IResultSet } from 'types'
import * as Yup from 'yup'

import AutoSave from 'components/Form/Autosave'

import FieldsContainer from './FieldsContainer'

const validationSchema = Yup.object().shape({})

//export type FormValues = Record<string, unknown>
export type FormValues = Partial<Pick<IFlowNode, 'connection' | 'settings'>>

interface Props {
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void
  onRefreshConnection: (connection: IModelWithId) => Promise<void>
  node: IFlowNode
  uiSchema: INodeItemSchema[]
  connections: IConnection[]
  inputs: IResultSet[]
}

const NodeForm: React.FC<Props> = ({ node, onSubmit, uiSchema, connections, onRefreshConnection, inputs }) => {
  const defaultValues: Record<string, unknown> = {
    connection: {
      publicId: node.connection ? node.connection.publicId : ''
    },
    settings: {
      ...node.settings
    }
  }

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Form className="space-y-2">
          <FieldsContainer
            uiSchema={uiSchema}
            connections={connections}
            values={values}
            node={node}
            onRefreshConnection={onRefreshConnection}
            inputs={inputs}
          />
          <div>
            <AutoSave button={true} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default NodeForm
