import React from 'react'
import styled from 'styled-components'
import { IIntegration } from 'types'

import { Button, PrimaryLinkButton } from 'components/Theme/Styles'

const Root = styled.div`
  padding: 1rem;
  background-color: var(--color-slate-100);
  border-radius: 0.4rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  &:hover {
    background-color: var(--color-slate-200);
  }
`
const Title = styled.div`
  font-size: 1.2rem;
`

const Logo = styled.img`
  display: block;
  width: 32px;
`
const Controls = styled.div`
  margin-left: auto;
`

interface AppBoxProps {
  integration: IIntegration
  onClickOAuth: (integration: IIntegration) => void
}

const AvailableIntegration: React.FC<AppBoxProps> = ({ integration, onClickOAuth }) => {
  let label = 'Connect'

  // Stupid hack to comply with Google oauth review
  if (integration.publicId == 'google_sheets') {
    label = 'Connect to Google Drive'
  }

  return (
    <Root>
      <div>
        <Logo src={integration.logo} />
      </div>
      <div>
        <Title>{integration.name}</Title>
        {integration.description ? integration.description : null}
      </div>
      <Controls>
        {integration.authType === 'API_KEY' ? (
          <PrimaryLinkButton to={`/integrations/create/api-key?integrationId=${integration.publicId}`}>
            Connect
          </PrimaryLinkButton>
        ) : (
          <Button primary={true} onClick={() => onClickOAuth(integration)}>
            {label}
          </Button>
        )}
      </Controls>
    </Root>
  )
}

export default AvailableIntegration
