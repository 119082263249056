import { faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IConnection } from 'types'

import ConfirmDelete from 'components/Button/ConfirmDelete'
import { Button } from 'components/Theme/Styles'

const Root = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border: 1px solid var(--color-slate-100);
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
`

const Logo = styled.img`
  display: block;
  height: 32px;
  margin: 1rem auto;
`

const Left = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
const Right = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

interface Props {
  connection: IConnection
  onRefresh: (connection: IConnection) => Promise<void>
  isLoading: boolean
  onDelete: (connection: IConnection) => Promise<void>
}

const Connection: React.FC<Props> = ({ connection, onRefresh, onDelete }) => {
  const [refreshing, setRefreshing] = useState(false)

  const handleRefresh = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setRefreshing(true)
    await onRefresh(connection)
    setRefreshing(false)
  }
  const handleDelete = () => {
    onDelete(connection)
  }

  return (
    <Root>
      <Left>
        <Logo src={connection.integration.logo} /> {connection.integration.name} ({connection.accountName}){' '}
      </Left>
      <Right>
        {connection.integration.canManualRefresh ? (
          <div>
            {refreshing ? (
              <>
                <Button disabled={true}>
                  <FontAwesomeIcon icon={faSpinner} spin={true} /> Refreshing...
                </Button>
              </>
            ) : (
              <Button onClick={handleRefresh}>Refresh</Button>
            )}
          </div>
        ) : null}
        <ConfirmDelete onConfirm={handleDelete}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </ConfirmDelete>
      </Right>
    </Root>
  )
}

export default Connection
