import { useQuery } from '@tanstack/react-query'
import { format, formatDistance } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { IFlowRun, RunStatus } from 'types'
import { parseAsUTc } from 'utils/date'

import Loading from 'components/Loading/Loading'
import Pagination from 'components/Pagination/Pagination'
import Table, { ColumnProperty } from 'components/Table/Table'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

const ErrorMessage = styled.div`
  font-size: 0.9rem;
`
const TimeTaken = styled.div`
  font-size: 0.9rem;
`
const Stats = styled.div``
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

interface FlowRunErrorProps {
  flowRun: IFlowRun
}

const FlowRunError: React.FC<FlowRunErrorProps> = ({ flowRun }) => {
  const errorNodeRun = flowRun.nodeRuns.find((it) => it.status == 'ERROR')
  if (!errorNodeRun) {
    return <ErrorMessage>{flowRun.errorMessage}</ErrorMessage>
  }

  return (
    <ErrorMessage>
      {errorNodeRun.errorMessage} ({errorNodeRun.node.name})
    </ErrorMessage>
  )
}

const FlowRunIndex = () => {
  const [page, setPage] = React.useState(1)
  const [size, setSize] = React.useState(25)

  const flowRunQuery = useQuery(['flow-runs', page], () =>
    api.getFlowRuns({
      page,
      size
    })
  )

  const columns: ColumnProperty<IFlowRun>[] = React.useMemo(() => {
    return [
      {
        name: 'Flow',
        render: (record) => record.flow.name
      },
      {
        name: 'Trigger',
        render: (record) => record.nodeRuns.find((it) => it.sequenceOrder == 0)?.node.name ?? '???'
      },
      {
        name: 'Nodes run',
        render: (record) => record.nodeRuns.length
      },
      {
        name: 'Run at',
        render: (record) => {
          const formatting = 'dd/MM/yyyy HH:mm'
          const date = format(parseAsUTc(record.createdAt), formatting)
          const completedAt = record.completedAt ? format(parseAsUTc(record.completedAt), formatting) : null
          const timeTaken = record.completedAt
            ? formatDistance(parseAsUTc(record.completedAt), parseAsUTc(record.createdAt))
            : null

          return (
            <>
              {date} {completedAt ? <TimeTaken>{timeTaken}</TimeTaken> : null}
            </>
          )
        }
      },
      {
        name: 'Status',
        render: (record) => <>{record.status == RunStatus.ERROR ? <FlowRunError flowRun={record} /> : record.status}</>,
        style: {
          maxWidth: '150px'
        }
      }
    ]
  }, [flowRunQuery.data])

  return (
    <>
      <Box>
        <Header>
          <Title>Flow runs</Title>
        </Header>
        <Content padding={false}>
          {flowRunQuery.isSuccess ? (
            <TableHeader>
              <Stats>Total runs: {flowRunQuery.data.total}</Stats>
              <Pagination page={page} total={flowRunQuery.data.total} size={size} onPage={(page) => setPage(page)} />
            </TableHeader>
          ) : null}
          {flowRunQuery.data ? (
            <Table rowKey={'publicId'} data={flowRunQuery.data.items} columns={columns} />
          ) : (
            <Loading />
          )}
        </Content>
      </Box>
    </>
  )
}

export default FlowRunIndex
