import { FormikHelpers } from 'formik'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { apiErrorsToFormikErrors } from 'utils/form'

import TeamForm, { TeamFormValues } from 'components/Team/TeamForm'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

import SettingsHeader from '../SettingsHeader'

const Root = styled.div``

const CreateTeam = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (values: TeamFormValues, helpers: FormikHelpers<TeamFormValues>) => {
    try {
      await api.createTeam(values)
      toast('New team created', { type: 'success' })
      navigate('/organisation/teams')
    } catch (e) {
      if (e instanceof APIError) {
        helpers.setErrors(apiErrorsToFormikErrors(e))
        toast(e.detail, { type: 'error' })
      } else {
        console.error(e)
      }
    }
  }

  return (
    <Root>
      <SettingsHeader />
      <Box>
        <Header>
          <Title>Create team</Title>
        </Header>
        <Content>
          <TeamForm onSubmit={handleSubmit} />
        </Content>
      </Box>
    </Root>
  )
}

export default CreateTeam
