import { useQuery } from '@tanstack/react-query'
import useOnClickOutside from 'hooks/useOnClickOutside'
import React, { DragEvent, useRef, useState } from 'react'
import styled from 'styled-components'

import Loading from 'components/Loading/Loading'

import * as api from 'services/api'

import ContentList from './ContentList'

const Root = styled.div`
  display: flex;
  gap: 1rem;
  position: absolute;
  top: 30px;
  left: 40px;
`

const SearchContainer = styled.div`
  input {
    border: 1px solid var(--color-gray-300);
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    width: 100%;
  }
`
const ContentWrapper = styled.div`
  position: relative;
  z-index: 999;
  background-color: var(--color-gray-50);
  padding: 1rem;
  box-shadow: var(--shadow-md);
  width: 500px;
`
const Background = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const NodeTypePicker = () => {
  const nodeTypesQuery = useQuery(['node-types'], () => api.getNodeTypes())
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const rootRef = useRef(null)

  const handleSearchQueryChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(evt.target.value)
  }

  const handleDragStart = (event: DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/tetra', nodeType)
    event.dataTransfer.effectAllowed = 'move'

    setIsDragging(true)
    setTimeout(() => setOpen(false), 1)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  useOnClickOutside(rootRef, () => open && setOpen(false))

  return (
    <Root>
      {nodeTypesQuery.data ? (
        <>
          <ContentWrapper ref={rootRef}>
            <SearchContainer onClick={() => setOpen(true)}>
              <input type="text" onChange={handleSearchQueryChange} placeholder="Add new node..." />
            </SearchContainer>
            <div style={{ display: open ? 'block' : 'none' }}>
              <ContentList
                paginatedList={nodeTypesQuery.data}
                onDrag={handleDragStart}
                onDragEnd={handleDragEnd}
                query={searchQuery}
              />
            </div>
          </ContentWrapper>
          {open && !isDragging ? <Background /> : null}
        </>
      ) : (
        <Loading />
      )}
    </Root>
  )
}

export default NodeTypePicker
