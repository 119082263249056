import { FieldArray } from 'formik'
import get from 'lodash/get'
import React from 'react'
import { IConnection, IFlowNode, IModelWithId, INodeItemSchema, IResultSet } from 'types'

import ConnectionField from 'components/Form/ConnectionField'
import Field from 'components/Form/Field'
import KeyValueArrayField from 'components/Form/KeyValueArrayField'
import SelectField from 'components/Form/SelectField'

import { createCustomComponent } from './createCustomComponent'

export const renderFields = async (
  uiSchema: INodeItemSchema[],
  values: Record<string, unknown>,
  connections: IConnection[],
  node: IFlowNode,
  onRefreshConnection: (connection: IModelWithId) => Promise<void>,
  inputs: IResultSet[]
): Promise<JSX.Element[]> => {
  const fields = uiSchema.map(async (item, idx) => {
    let content = null
    const label = item.type === 'group' ? null : item.label

    switch (item.type) {
      case 'select':
        content = <SelectField name={`settings.${item.name}`} options={item.options} clearable={item.clearable} />
        break
      case 'text':
        content = <Field type="text" name={`settings.${item.name}`} as={item.multiLine ? 'textarea' : 'input'} />
        break
      case 'number':
        content = <Field type="number" name={`settings.${item.name}`} />
        break
      case 'checkbox':
        content = <Field type="checkbox" name={`settings.${item.name}`} />
        break
      case 'key-value':
        content = (
          <FieldArray
            name={`settings.${item.name}`}
            render={(helpers) => (
              <KeyValueArrayField
                {...helpers}
                keyLabel={item.keyLabel}
                valueLabel={item.valueLabel}
                keyPlaceholder={item.keyPlaceholder}
                valuePlaceholder={item.valuePlaceholder}
                keyOptions={item.keyOptions}
                valueOptions={item.valueOptions}
              />
            )}
          />
        )
        break
      case 'connection':
        content = <ConnectionField connections={connections} onRefresh={onRefreshConnection} />
        break
      case 'dynamic':
        content = await createCustomComponent(item, node, values, inputs)
        break
      case 'group':
        if (item.renderIf) {
          const currentValue = get(values.settings, item.renderIf.field)
          const childContents = await renderFields(item.fields, values, connections, node, onRefreshConnection, inputs)
          if (currentValue === item.renderIf.value) {
            return childContents
          } else {
            return []
          }
        }
    }

    return (
      <div key={idx}>
        {label && <label>{label}</label>}
        {content}
      </div>
    )
  })

  const results = await Promise.all(fields)

  return results.flat(1)
}
