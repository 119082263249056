import { faPenToSquare, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  padding: 1rem 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`
interface Props {
  onEditClick: (evt: React.MouseEvent) => void
  onDeleteClick: (evt: React.MouseEvent) => void
}

const Controls: React.FC<Props> = ({ onEditClick, onDeleteClick }) => {
  return (
    <Root>
      <a href="#" onClick={onEditClick}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </a>
      <a href="#" onClick={onDeleteClick}>
        <FontAwesomeIcon icon={faTrash} />
      </a>
    </Root>
  )
}

export default React.memo(Controls)
