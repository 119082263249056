import { faBoltLightning, faCog, faHouse, faPlug, faUsersCrown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from 'assets/logo.png'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { IUser } from 'types'

import RenderIfCan from 'components/Permissions/RenderIfCan'

const Root = styled.div`
  align-items: center;
`
const LogoSection = styled.div`
  margin-bottom: 2rem;
`

interface LogoImageProps {
  animate: boolean
}

const Logo = styled.img<LogoImageProps>`
  display: block;
  width: 120px;
`

const LogoWrapper = styled.div`
  display: inline-block;
  display: flex;
`

const MenuItems = styled.div`
  padding: 0;
  margin: 0;
`
const Item = styled(Link)`
  display: block;
  color: #1f4d63;
  text-decoration: none;
  margin-bottom: 1rem;

  &:visted {
    color: #1f4d63;
  }

  &:hover {
    color: var(--color-blue-700);
  }
`

interface Props {
  user: IUser
}

const SideBar: React.FC<Props> = ({ user }) => {
  const location = useLocation()
  const [animate, setAnimate] = React.useState(false)
  React.useEffect(() => {
    setAnimate(true)
    setTimeout(() => setAnimate(false), 2000)
  }, [location.pathname])

  return (
    <Root>
      <LogoSection>
        <Link to={'/'}>
          <LogoWrapper>
            <Logo animate={animate} src={logo} alt="Logo" />
          </LogoWrapper>
        </Link>
      </LogoSection>
      <MenuItems>
        <Item to={'/'}>
          <FontAwesomeIcon icon={faHouse} fixedWidth={true} /> Dashboard
        </Item>
        <Item to={'/flow-runs'}>
          <FontAwesomeIcon icon={faBoltLightning} fixedWidth={true} /> Runs
        </Item>
        <Item to={`/integrations`}>
          <FontAwesomeIcon icon={faPlug} fixedWidth={true} /> Integrations
        </Item>
        <RenderIfCan action="manage-settings">
          <Item to={'/organisation/users'}>
            <FontAwesomeIcon icon={faCog} fixedWidth={true} /> Settings
          </Item>
        </RenderIfCan>
        {user.isSuperAdmin && (
          <Item to={'/admin'}>
            <FontAwesomeIcon icon={faUsersCrown} fixedWidth={true} /> Admin
          </Item>
        )}
      </MenuItems>
    </Root>
  )
}

export default SideBar
