import { UserRole } from 'consts'
import { JsonObject } from 'react-use-websocket/dist/lib/types'

export interface IUser {
  publicId: string
  authToken?: string
  name: string
  emailAddress: string
  isSuperAdmin?: boolean
}

export type ILoggedInUser = Required<IUser>
export type IPublicUser = Pick<IUser, 'publicId' | 'emailAddress' | 'name'>

export interface IErrorItem {
  loc: string[]
  type: string
  msg: string
}

export interface IResponseError {
  detail: string
  errors: IErrorItem[]
  code: string
}

export interface PaginatedResults<T> {
  total: number
  page: number
  size: number
  items: Array<T>
}

export interface IModelWithId {
  publicId: string
}

export type ISubscriptionStatus = 'active' | 'trialing' | 'canceled' | 'past_due'

export interface ISubscription {
  publicId: string
  status: ISubscriptionStatus
  plan: string
  trialStartAt: string
  trialEndAt: string
  startedAt: string
  cancelledAt: string
}

export interface IWorld {
  user: IUser
  subscription: ISubscription
  todos: ITodo[]
  organisation: IOrganisation
  member: IOrganisationMember
}

export interface IOrganisation {
  publicId: string
  name: string
}

export interface IOrganisationMember {
  publicId: string
  role: UserRole
}

export interface IMember {
  publicId: string
  user: IUser
  role: UserRole
}

export interface IInvite {
  publicId: string
  emailAddress: string
  createdAt: string
}

export interface ITodo {
  kind: string
}

export interface ITeam {
  publicId: string
  name: string
  totalUsers: number
  createdAt: string
}

export interface ITeamMember {
  publicId: string
  user: IPublicUser
  team: ITeam
}

export interface IFlow {
  publicId: string
  name: string
  nodes: IFlowNode[]
  edges: INodeEdge[]
}

export interface IFlowWithLastRun extends IFlow {
  lastFlowRun?: IFlowRun
}

export interface IFlowNode<C = unknown, S = Record<string, unknown>> {
  publicId: string
  name: string
  left: number
  top: number
  settings: S
  kind: string
  flow: IModelWithId
  connection?: IConnection<C>
  nodeType: INodeType
}

export interface INodeEdge {
  publicId: string
  fromNode: {
    publicId: string
    name: string
  }
  toNode: {
    publicId: string
    name: string
  }
}

export interface INodeData {
  objects: INodeResultSetItem[]
  columns: string[]
  total: number
  nodeRun: INodeRun
  createdAt: string
}

export interface INodeResultSetItem {
  publicId: string
  parsedData: Record<string, unknown>
}

export enum RunStatus {
  QUEUED = 'QUEUED',
  SUCCESS = 'SUCCESS',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR'
}

export interface INodeRun {
  status: RunStatus
  createdAt: string
  completedAt: string
  node: Pick<IFlowNode, 'publicId' | 'name'>
  errorMessage: string | null
  sequenceOrder: number
}

export interface IIntegration {
  publicId: string
  name: string
  authType: 'API_KEY' | 'OAUTH2'
  logo: string
  canManualRefresh: boolean
  setupParams?: IIntegrationSetupParam[]
  description: string | null
}

export interface IIntegrationSetupParam {
  name: string
  label: string
  type: string
}

export interface IConnection<T = unknown> {
  publicId: string
  integration: IIntegration
  accountName: string
  apiKey: string
  accountData: T
  createdAt: string
  updatedAt: string
}

export interface INodeType {
  publicId: string
  name: string
  description: string
  kind: string
  isTrigger: boolean
  logoUrl: string
  vendor: string
  category: string | null
  subCategory: string | null
}

export interface IFlowRun {
  publicId: string
  nodeRuns: INodeRun[]
  isFullRun: boolean
  completedAt: string
  status: RunStatus
  errorMessage: string | null
  flow: Pick<IFlow, 'publicId' | 'name'>
  createdAt: string
}

export interface IResultSet {
  publicId: string
  totalItems: number
  columns: string[]
  createdAt: string
}

export enum WebsocketEventType {
  NODE_RUN_STATUS = 'NODE_RUN_STATUS',
  FLOW_RUN_STATUS = 'FLOW_RUN_STATUS'
}

export interface IWebsocketPayload extends JsonObject {
  update: {
    event: WebsocketEventType
    status: RunStatus
    node: string
    nodeName: string
    error: string
  }
}

export type INodeItemSchema =
  | ISelectNodeField
  | ITextNodeField
  | IKeyValueNodeField
  | IGroupedFields
  | INumberNodeField
  | IConnectionField
  | IGroupField
  | IDynamicField
  | ICheckboxField

export interface IGroupedFields {
  type: 'group'
  fields: INodeItemSchema[]
  renderIf: {
    field: string
    operator: '=='
    value: string
  }
}

export interface INumberNodeField extends IBaseNodeField {
  type: 'number'
}

export interface IKeyValueNodeField extends IBaseNodeField {
  type: 'key-value'
  keyPlaceholder: string
  valuePlaceholder: string
  keyLabel: string
  valueLabel: string
  keyOptions?: {
    label: string
    value: string
  }[]
  valueOptions?: {
    label: string
    value: string
  }[]
}

export interface ISelectNodeField extends IBaseNodeField {
  type: 'select'
  options: { label: string; value: string }[]
  clearable?: boolean
}

export interface ITextNodeField extends IBaseNodeField {
  type: 'text'
  multiLine: boolean
}

export interface IConnectionField extends IBaseNodeField {
  type: 'connection'
}

export interface IGroupField extends IBaseNodeField {
  type: 'group'
  fields: INodeItemSchema[]
  renderIf?: {
    field: string
    value: string
  }
}

export interface ICheckboxField extends IBaseNodeField {
  type: 'checkbox'
}

export interface IBaseNodeField {
  label: string
  name: string
}

export interface IDynamicField extends IBaseNodeField {
  type: 'dynamic'
  path: string
  customProps?: Record<string, unknown>
}

export type UISchemaNodeTypes = INodeItemSchema['type']

export interface IConnectRequest extends IModelWithId {
  integration: IIntegration
  status: 'PENDING' | 'CONNECTED'
  setupParams: Record<string, string>
}
