import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { IIntegrationSetupParam } from 'types'
import * as Yup from 'yup'

import Field from 'components/Form/Field'
import { Button } from 'components/Theme/Styles'

interface Props {
  setupParams: IIntegrationSetupParam[]
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void
}

export type FormValues = Record<string, unknown>

const SetupParamsForm: React.FC<Props> = ({ onSubmit, setupParams }) => {
  const defaultValues = setupParams.reduce((prev, current) => {
    return {
      ...prev,
      [current.name]: ''
    }
  }, {})

  return (
    <Formik<FormValues> initialValues={defaultValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form className="space-y-2">
          {setupParams.map((it) => (
            <div key={it.name}>
              <label>{it.label}</label>
              <Field type={it.type} name={it.name} />
            </div>
          ))}
          <div>
            <Button type="submit" disabled={isSubmitting}>
              Update
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default SetupParamsForm
