import { useQuery } from '@tanstack/react-query'
import React from 'react'

import Empty from 'components/Empty/Empty'
import FlowRow from 'components/Flow/Row'
import { Box, Content, Header, PrimaryLinkButton, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

const Dashboard = () => {
  const flowQuery = useQuery(['flows'], () => api.getFlows())

  return (
    <>
      <Box>
        <Header>
          <Title>Dashboard</Title>
          <div>
            <PrimaryLinkButton to={`/flow/create`}>Create flow</PrimaryLinkButton>
          </div>
        </Header>
        <Content padding={false}>
          {flowQuery.data?.items.map((flow) => {
            return <FlowRow key={flow.publicId} flow={flow} />
          })}
          {flowQuery.isFetched && flowQuery.data?.total === 0 && (
            <Empty message="You don't have any flows" actionUrl="/flow/create" actionLabel="Create new flow"></Empty>
          )}
        </Content>
      </Box>
    </>
  )
}

export default Dashboard
