import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArrayRenderProps, useField } from 'formik'
import React from 'react'
import { assignUniqueKeysToParts } from 'react-intl/src/utils'
import styled from 'styled-components'

import Field from 'components/Form/Field'

import SelectField from './SelectField'

const Root = styled.div`
  border: 1px solid var(--color-gray-100);
`
const Header = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.5rem 1rem;
  background-color: var(--color-gray-100);
`
const Row = styled.div`
  width: 100%;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 2fr 2fr 1fr;
  padding: 0.5rem 1rem;
  align-items: center;

  select,
  input {
    width: 100%;
  }
`
const Delete = styled.a`
  color: var(--color-red-400);
`
const Add = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
`

interface CellProps {
  right?: boolean
}
const Actions = styled.div<CellProps>`
  text-align: ${(props) => (props.right ? 'right' : 'left')};

  > a {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
`

type Props = FieldArrayRenderProps & {
  keyPlaceholder: string
  valuePlaceholder: string
  keyLabel: string
  valueLabel: string
  keyOptions?: { label: string; value: string }[]
  valueOptions?: { label: string; value: string }[]
}

const KeyValueArrayField: React.FC<Props> = (props) => {
  const [field] = useField<Array<{ key: string; value: string }>>(props.name)

  return (
    <Root>
      <Header>
        <div>{props.keyLabel}</div>
        <div>{props.valueLabel}</div>
        <div></div>
      </Header>
      {field.value.map((_, index: number) => {
        return (
          <Row key={index}>
            <div>
              {props.keyOptions ? (
                <SelectField name={`${field.name}[${index}].key`} options={props.keyOptions} />
              ) : (
                <Field type="text" name={`${field.name}[${index}].key`} placeholder={props.keyPlaceholder} />
              )}
            </div>
            <div>
              {props.valueOptions ? (
                <SelectField name={`${field.name}[${index}].value`} options={props.valueOptions} />
              ) : (
                <Field type="text" name={`${field.name}[${index}].value`} placeholder={props.valuePlaceholder} />
              )}
            </div>
            <Actions>
              <Delete href="#delete" onClick={props.handleRemove(index)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Delete>
            </Actions>
          </Row>
        )
      })}
      <Add>
        <a href="#Add" onClick={props.handlePush({ key: '', value: '' })}>
          <FontAwesomeIcon icon={faPlusCircle} /> Add item
        </a>
      </Add>
    </Root>
  )
}

export default KeyValueArrayField
