import React from 'react'
import { toast } from 'react-toastify'
import { Node, useReactFlow } from 'reactflow'
import { IFlowNode } from 'types'

import * as api from 'services/api'
import { APIError } from 'services/transport'

interface ShowModalState {
  show: boolean
  nodeId?: string
}

const createGraphProviderContextState = () => {
  const [activeNode, setActiveNode] = React.useState<ShowModalState>({
    show: false
  })
  const { setNodes } = useReactFlow()

  const removeNodeFromFlow = (node: IFlowNode) => {
    setNodes((nodes: Node[]) => {
      return nodes.filter((it) => it.id !== node.publicId)
    })
  }

  const deleteNode = async (node: IFlowNode) => {
    try {
      await api.deleteNode(node.publicId)
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      }
      return
    }

    removeNodeFromFlow(node)
  }

  return {
    activeNode,
    setActiveNode,
    deleteNode
  }
}

// Create a type from the return values of _useDataProvider
type GlobalContextType = ReturnType<typeof createGraphProviderContextState>

// Main context provider
const GlobalContext = React.createContext<GlobalContextType | undefined>(undefined)

type Props = {
  children?: React.ReactNode
}

// Provides data for a single spreadsheet component
export const GraphProvider: React.FC<Props> = ({ children }) => {
  const value = createGraphProviderContextState()
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

const useGraph = () => {
  const context = React.useContext(GlobalContext)

  if (context === undefined) {
    throw new Error('Graph context is undefined')
  }

  return context
}

export default useGraph
