import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import './reset.css'
import './variables.css'

const container = document.getElementById('root')
if (!container) {
  console.error('Could not find root element to use for mounting')
} else {
  const root = createRoot(container)
  root.render(<App />)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
