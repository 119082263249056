import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ITeamMember } from 'types'
import { apiErrorsToFormikErrors } from 'utils/form'

import ConfirmDelete from 'components/Button/ConfirmDelete'
import Loading from 'components/Loading/Loading'
import { useModal } from 'components/Modal/useModal'
import DataGrid, { ColumnProperty } from 'components/Table/Table'
import TeamMemberForm, { TeamMemberFormValues } from 'components/Team/TeamMemberForm'
import { Box, Button, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

import SettingsHeader from '../SettingsHeader'

const Root = styled.div``
const Actions = styled.div`
  text-align: right;
`
const ModalContainer = styled.div`
  padding: 1rem;
  width: 400px;
`
const EmptyState = styled.div`
  padding: 1rem;
`

type UrlParams = {
  id: string
}

const ShowTeam = () => {
  const { id } = useParams<UrlParams>()
  const { setModal, closeModal } = useModal()
  const queryClient = useQueryClient()

  if (!id) {
    throw new Error('ID is required')
  }

  const team = useQuery(['team', id], () => api.getTeam(id))
  const members = useQuery(['team-members', id], () => api.getTeamMembers(team.data!), {
    enabled: !!team.data
  })

  const handleRemoveMember = async (userId: string) => {
    if (!team.data) {
      console.error('Team data not loaded')
      return
    }
    try {
      await api.deleteTeamMember(team.data, userId)
      toast('User was removed from the team', { type: 'success' })
      queryClient.invalidateQueries(['team-members'])
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      } else {
        toast('There was a problem removing this user from the team', { type: 'error' })
        console.error(e)
      }
    }
  }

  const handleAddUser = async (values: TeamMemberFormValues, helpers: FormikHelpers<TeamMemberFormValues>) => {
    if (!team.data) {
      console.error('Team data not loaded')
      return
    }

    try {
      await api.addUserToTeam(team.data, values.email)
      queryClient.invalidateQueries(['team-members'])
      toast('User has been added to team')
      closeModal()
    } catch (e) {
      if (e instanceof APIError) {
        helpers.setErrors(apiErrorsToFormikErrors(e))
      } else {
        toast('There was a problem adding user to the team', { type: 'error' })
      }
    }
  }

  const showAddUserModal = () => {
    setModal(
      <ModalContainer>
        <TeamMemberForm onSubmit={handleAddUser} />
      </ModalContainer>
    )
  }

  if (!team.data || !members.data) {
    return <Loading />
  }

  const columns: ColumnProperty<ITeamMember>[] = [
    {
      name: 'Member',
      render: (row) => row.user.name
    },
    {
      name: 'Email',
      render: (row) => row.user.emailAddress
    },
    {
      name: '',
      render: (row) => (
        <Actions>
          <ConfirmDelete
            message="Are you sure you want to remove this user from the team?"
            onConfirm={() => handleRemoveMember(row.user.publicId)}
          >
            Remove member
          </ConfirmDelete>
        </Actions>
      )
    }
  ]

  return (
    <Root>
      <SettingsHeader />
      <Box>
        <Header>
          <Title>Team {team.data?.name}</Title>
          <div>
            <Button primary={true} onClick={showAddUserModal}>
              Add user
            </Button>
          </div>
        </Header>
        <Content padding={false}>
          {members.isFetched && members.data.length > 0 ? (
            <DataGrid rowKey={'publicId'} data={members.data} columns={columns} />
          ) : null}
          {members.isFetched && members.data.length == 0 ? <EmptyState>No members found</EmptyState> : null}
        </Content>
      </Box>
    </Root>
  )
}

export default ShowTeam
