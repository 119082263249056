import React, { ReactElement } from 'react'

export const renderCell = (value: unknown): ReactElement | string => {
  if (value === null || value === undefined) {
    return <span style={{ fontStyle: 'italic', color: 'var(--color-gray-400)' }}>{`empty`}</span>
  }

  switch (typeof value) {
    case 'string':
      return value
    case 'number':
      return value.toString()
    case 'boolean':
      return value ? 'true' : 'false'
    default:
      return JSON.stringify(value)
  }
}
