import React from 'react'
import { IDynamicField, IFlowNode, IResultSet } from 'types'

export interface ICustomComponentProps<T = Record<string, unknown>> {
  values: Record<string, unknown>
  node: IFlowNode
  inputs: IResultSet[]
  customProps?: T
}

export const createCustomComponent = async (
  item: IDynamicField,
  node: IFlowNode,
  values: Record<string, unknown>,
  inputs: IResultSet[]
) => {
  const Component = (await import(`../../${item.path}`)).default as React.FC<ICustomComponentProps>
  return <Component values={values} node={node} inputs={inputs} customProps={item.customProps} />
}
