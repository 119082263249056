import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import Loading from 'components/Loading/Loading'
import { Box, Content } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  width: 30rem;
  margin: 10rem auto;
`

const ErrorMessage = styled.div`
  padding: 1rem;
  border: 1px solid var(--color-gray-300);
  margin: 1rem 0;
`

const OAuthComplete: React.FC = () => {
  const [error, setError] = React.useState<string>()
  const query = new URLSearchParams(useLocation().search)
  const code = query.get('code')
  const state = query.get('state')
  const navigate = useNavigate()

  const completeGoogleAuth = async () => {
    try {
      const response = await api.completeOAuth(code!, state!)
      navigate('/integrations')
      toast('Your account has been connected', { type: 'success' })
    } catch (e) {
      if (e instanceof APIError) {
        setError(e.message)
      } else {
        setError('There was an error logging you in')
      }
    }
  }

  React.useEffect(() => {
    if (!code) {
      return
    }
    completeGoogleAuth()
  }, [code])

  if (!code) {
    return <div>Code does not exist in URL!</div>
  }

  return (
    <Root>
      <Box>
        <Content>
          {error ? (
            <div>
              <p>There was a problem connecting your account</p>
              <ErrorMessage>{error}</ErrorMessage>
              <div>
                <Link to={`/login`}>Back to login</Link>
              </div>
            </div>
          ) : (
            <div>
              <Loading /> Connecting your account{' '}
            </div>
          )}
        </Content>
      </Box>
    </Root>
  )
}

export default OAuthComplete
