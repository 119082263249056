import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import Field from 'components/Form/Field'
import GeneralError from 'components/Form/GeneralError'
import { Button } from 'components/Theme/Styles'

interface Props {
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void | Promise<void>
}

export interface FormValues {
  name: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('A name is required').min(1)
})

const FlowForm: React.FC<Props> = ({ onSubmit }) => {
  const defaultValues = {
    name: ''
  }
  return (
    <Formik validationSchema={validationSchema} initialValues={defaultValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form className="space-y-2">
          <GeneralError />
          <div>
            <label className="block">Name</label>
            <Field autoFocus={true} className="w-full" type="text" name="name" help="A name for this flow" />
          </div>
          <div>
            <Button primary={true} type="submit" disabled={isSubmitting}>
              Create new flow
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FlowForm
