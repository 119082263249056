import { Icon } from '@fortawesome/fontawesome-svg-core'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { IConnection, IIntegration } from 'types'

import AvailableIntegration from 'components/Integration/AvailableIntegration'
import Connection from 'components/Integration/Connection'
import SetupParamsForm from 'components/Integration/SetupParamsForm'
import { useModal } from 'components/Modal/useModal'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

const IntegrationsList = styled.div`
  gap: 1rem;

  > div {
    margin-bottom: 1rem;
  }
`
const ConnectionsList = styled.div``
const Area = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
`
const ShowParamsModal = styled.div`
  padding: 1rem;
`

const IntegrationIndex = () => {
  const integrationsQuery = useQuery(['integrations'], () => api.getIntegrations())
  const connectionsQuery = useQuery(['connections'], () => api.getConnections())
  const oauthWindow = React.useRef<Window>()
  const { setModal } = useModal()

  const handleRefreshConnection = async (connection: IConnection) => {
    try {
      await api.refreshConnection(connection.publicId)
      toast('Connection settings updated', { type: 'success' })
    } catch (e) {
      console.error(e)
    }
  }

  const handleKickstartOAuth = async (integration: IIntegration, values?: Record<string, unknown>) => {
    try {
      const response = await api.kickstartOAuth(integration.publicId, values)
      window.location.href = response.url
    } catch (e) {
      console.error(e)
    }
  }

  const handleShowParams = async (integration: IIntegration) => {
    if (integration.setupParams && integration.setupParams.length > 0) {
      setModal(
        <ShowParamsModal>
          <SetupParamsForm
            setupParams={integration.setupParams}
            onSubmit={(values) => handleKickstartOAuth(integration, values)}
          />
        </ShowParamsModal>
      )
    } else {
      return handleKickstartOAuth(integration)
    }
  }

  const handleDeleteConnection = async (connection: IConnection) => {
    try {
      await api.deleteConnection(connection.publicId)
      toast('Connection has been deleted', { type: 'warning' })
      connectionsQuery.refetch()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <Box>
        <Header>
          <Title>Integrations</Title>
        </Header>
        <Content>
          <Area>
            <h2>Connected accounts</h2>
            {connectionsQuery.data && connectionsQuery.data.length > 0 ? (
              <ConnectionsList>
                {connectionsQuery.data?.map((it) => (
                  <Connection
                    key={it.publicId}
                    connection={it}
                    onRefresh={handleRefreshConnection}
                    isLoading={false}
                    onDelete={handleDeleteConnection}
                  />
                ))}
              </ConnectionsList>
            ) : (
              <p>You have not connected to any external services.</p>
            )}
          </Area>

          <Area>
            <h2>Available integrations</h2>
            <IntegrationsList>
              {integrationsQuery.data?.map((it) => (
                <AvailableIntegration key={it.publicId} integration={it} onClickOAuth={handleShowParams} />
              ))}
            </IntegrationsList>
          </Area>
        </Content>
      </Box>
    </div>
  )
}

export default IntegrationIndex
