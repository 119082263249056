import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'

import Modal from 'components/Modal/Modal'

import { useOnClickOutside } from './useModal'

interface Props {
  modal?: ReactElement
  onClickOutside: () => void
}

export const ModalRoot: React.FC<Props> = ({ modal, onClickOutside }) => {
  //const ref = React.useRef<HTMLDivElement>()
  const ref = React.createRef<HTMLDivElement>()

  useOnClickOutside(ref, onClickOutside)

  if (!modal) {
    return null
  }

  return ReactDOM.createPortal(<Modal ref={ref}>{modal}</Modal>, document.body)
}

export default ModalRoot
