import { Icon } from '@fortawesome/fontawesome-svg-core'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IConnection } from 'types'

import SelectField from 'components/Form/SelectField'

const Refresh = styled.div`
  margin-left: 0.5rem;
  display: inline-block;
`

const Root = styled.div``
const CreateNewConnection = styled.div`
  margin-top: 0.25rem;

  a {
    text-decoration: underline;
  }
`

type PartialConnection = Pick<IConnection, 'publicId'>

interface Props {
  connections: IConnection[]
  onRefresh?: (connection: PartialConnection) => Promise<void>
}

interface FormValues {
  connection?: PartialConnection
}

const ConnectionField: React.FC<Props> = ({ connections, onRefresh }) => {
  const [refreshing, setRefreshing] = useState(false)
  const [connection, setConnection] = useState<IConnection>()
  const form = useFormikContext<FormValues>()

  const options = useMemo(() => {
    return connections.map((it) => ({
      label: it.accountName,
      value: it.publicId
    }))
  }, [connections])

  // Set connection state to currently set connection
  useEffect(() => {
    if (form.values.connection) {
      const foundConnection = connections.find((it) => it.publicId == form.values.connection?.publicId)
      if (foundConnection) {
        setConnection(foundConnection)
      } else {
        setConnection(undefined)
      }
    } else {
      if (connection) {
        setConnection(undefined)
      }
    }
  }, [form.values.connection])

  const handleRefreshClick = React.useCallback(
    async (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault()
      if (!form.values.connection || !onRefresh) {
        return
      }

      setRefreshing(true)
      await onRefresh(form.values.connection)
      setRefreshing(false)
    },
    [connections, form.values.connection]
  )

  return (
    <Root>
      <SelectField name="connection.publicId" options={options} />{' '}
      {form.values.connection?.publicId && connection && connection.integration.canManualRefresh && (
        <Refresh>
          <a href="#refresh" title="Refresh connection data" onClick={handleRefreshClick}>
            <FontAwesomeIcon icon={faArrowsRotate} spin={refreshing} />{' '}
          </a>
        </Refresh>
      )}
      <CreateNewConnection>
        Or <Link to={`/integrations`}>create new connection</Link>
      </CreateNewConnection>
    </Root>
  )
}

export default ConnectionField
