import { FormikHelpers } from 'formik'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { apiErrorsToFormikErrors } from 'utils/form'

import RegisterForm, { RegisterFormValues } from 'components/RegisterForm/RegisterForm'
import { Box, Content } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  width: 30rem;
  margin: 10rem auto;

  > h2 {
    margin-bottom: 1rem;
  }
`
const FooterMessage = styled.div`
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-gray-200);
  padding: 1rem;
  border-radius: 0.4rem;

  > a {
    text-decoration: none;
  }
`

const Register: React.FC = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values: RegisterFormValues, { setErrors }: FormikHelpers<RegisterFormValues>) => {
    try {
      await api.createUser(values)
      navigate('/register/success')
    } catch (e) {
      if (e instanceof APIError) {
        setErrors(apiErrorsToFormikErrors(e))
      }
      console.error(e)
    }
  }

  return (
    <Root>
      <h2>Create new account</h2>
      <Box>
        <Content>
          <RegisterForm onSubmit={handleSubmit} />
        </Content>
      </Box>
      <FooterMessage>
        Already have an account? <Link to={'/login'}>Login here</Link>
      </FooterMessage>
    </Root>
  )
}

export default Register
