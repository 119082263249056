import { FormikHelpers } from 'formik'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { apiErrorsToFormikErrors } from 'utils/form'
import { popOAuthRequest, removeOAuthRequest } from 'utils/storage'

import LoginForm, { LoginFormValues } from 'components/LoginForm/LoginForm'
import { Box, Content } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  width: 30rem;
  margin: 10rem auto;

  > h2 {
    margin-bottom: 1rem;
  }
`
const FooterMessage = styled.div`
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-gray-200);
  padding: 1rem;
  border-radius: 0.4rem;

  > a {
    text-decoration: none;
  }
`

const Login: React.FC = () => {
  const { login } = useAuth()
  const [oauthRequest] = useState(popOAuthRequest())

  const handleSubmit = async (values: LoginFormValues, { setErrors }: FormikHelpers<LoginFormValues>) => {
    try {
      await login(values.emailAddress, values.password)

      // If there's a pending oauth request, complete the request
      if (oauthRequest) {
        try {
          await api.completeOAuth(oauthRequest.code, oauthRequest.state)
          removeOAuthRequest(oauthRequest.code)
          toast('OAuth connection created', { type: 'success' })
        } catch (e) {
          // could be because code is not valid any more
        }
      }
    } catch (e) {
      if (e instanceof APIError) {
        setErrors(apiErrorsToFormikErrors(e))
      }
      console.error(e)
    }
  }

  return (
    <Root>
      <h2>Login</h2>
      <Box>
        <Content data-testid="login-page">
          <LoginForm onSubmit={handleSubmit} />
        </Content>
      </Box>
      <FooterMessage>
        <Link to={'/register'}>Create a new account</Link>
        <Link to={`/verify/send`}>Resend verification code</Link>
      </FooterMessage>
    </Root>
  )
}

export default Login
