import { UserRole } from 'consts'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { IMember } from 'types'
import { roleToText } from 'utils/text'
import * as Yup from 'yup'

import SelectField from 'components/Form/SelectField'

const validationSchema = Yup.object().shape({
  role: Yup.string().required('A role is required')
})

interface Props {
  onSubmit: (values: UserRoleFormValues, helpers: FormikHelpers<UserRoleFormValues>) => void
  member: IMember
}

export type UserRoleFormValues = {
  role: string
}

const getMemberRoles = () => {
  return Object.keys(UserRole)
    .filter((v) => isNaN(+v))
    .map((e) => ({
      label: roleToText(e as UserRole),
      value: e
    }))
}

const UserRoleForm: React.FC<Props> = ({ member, onSubmit }) => {
  const defaultValues = {
    role: member.role
  }
  return (
    <Formik<UserRoleFormValues> validationSchema={validationSchema} initialValues={defaultValues} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Form>
          <SelectField name="role" options={getMemberRoles()} onChangeValue={submitForm} />
        </Form>
      )}
    </Formik>
  )
}

export default UserRoleForm
