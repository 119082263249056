import React, { DragEvent } from 'react'
import styled from 'styled-components'
import { INodeType } from 'types'

const NodeTile = styled.div`
  cursor: grab;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:hover {
    background-color: var(--color-slate-100);
  }
`
const CategoryTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1rem;
`
const Root = styled.div``
const Logo = styled.img`
  width: 16px;
  height: 16px;
`
const EmptyCategory = styled.div`
  color: var(--color-gray-500);
`

interface Props {
  name: string
  nodeTypes: INodeType[]
  onDrag: (event: DragEvent<HTMLDivElement>, nodeType: string) => void
  onDragEnd: () => void
}

const NodeTypeCategory: React.FC<Props> = ({ name, nodeTypes, onDrag, onDragEnd }) => {
  return (
    <Root>
      <CategoryTitle>{name}</CategoryTitle>
      {nodeTypes.map((it) => {
        return (
          <NodeTile key={it.kind} onDragStart={(event) => onDrag(event, it.kind)} draggable onDragEnd={onDragEnd}>
            <Logo src={it.logoUrl} /> {it.name}
          </NodeTile>
        )
      })}
      {nodeTypes.length == 0 && <EmptyCategory>Nothing found.</EmptyCategory>}
    </Root>
  )
}

export default NodeTypeCategory
