import { useQuery } from '@tanstack/react-query'
import useGlobal from 'hooks/useGlobal'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { parseAsUTc } from 'utils/date'

import Loading from 'components/Loading/Loading'
import { Box, buttonCss, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

const LinkButton = styled.a`
  ${buttonCss}
`

const Billing = () => {
  const { subscription } = useGlobal()
  const stripeBilling = useQuery(['stripe-billing'], () => api.getStripeBillingPortalUrl())

  if (!subscription) {
    return <p>Could not find subscription!. Please contact support.</p>
  }

  const endDate = parseAsUTc(subscription.trialEndAt)

  return (
    <Box>
      <Header>
        <Title>Billing</Title>
      </Header>
      <Content>
        {subscription.status !== 'canceled' && <p>Current plan: {subscription.plan}</p>}

        {subscription.trialStartAt && subscription.status !== 'canceled' ? (
          <p>
            You are currently trialing this product, your trial will finish on <FormattedDate value={endDate} />
          </p>
        ) : null}

        {subscription.status === 'canceled' ? <div>You have canceled your subscription.</div> : null}

        <br />
        {stripeBilling.isFetched ? (
          <div>
            <LinkButton href={stripeBilling.data?.url} rel="noreferrer">
              Manage subscription
            </LinkButton>
          </div>
        ) : (
          <Loading />
        )}
      </Content>
    </Box>
  )
}

export default Billing
