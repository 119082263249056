import React from 'react'
import { IOrganisation, IOrganisationMember, ISubscription, ITodo } from 'types'

const useGlobalProvider = () => {
  const [subscription, setSubscription] = React.useState<ISubscription>()
  const [todos, setTodos] = React.useState<ITodo[]>([])
  const [organisation, setOrganisation] = React.useState<IOrganisation>()
  const [organisationMember, setOrganisationMember] = React.useState<IOrganisationMember>()

  return {
    subscription,
    setSubscription,
    setTodos,
    todos,
    organisation,
    setOrganisation,
    setOrganisationMember,
    organisationMember
  }
}

// Create a type from the return values of _useDataProvider
type GlobalContextType = ReturnType<typeof useGlobalProvider>

// Main context provider
const GlobalContext = React.createContext<GlobalContextType | undefined>(undefined)

type Props = {
  children?: React.ReactNode
}

// Provides data for a single spreadsheet component
export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const value = useGlobalProvider()
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

const useGlobal = () => {
  const context = React.useContext(GlobalContext)

  if (context === undefined) {
    throw new Error('Global context is undefined')
  }

  return context
}

export default useGlobal
