import React from 'react'
import { NodeProps, Position } from 'reactflow'
import styled from 'styled-components'

import CustomHandle from './Handle'
import NodeRoot from './NodeRoot'

const Logo = styled.img`
  width: 48px;
`

const Node: React.FC<NodeProps<any>> = (props) => {
  return (
    <NodeRoot {...props}>
      <>
        <Logo src={props.data.nodeType.logoUrl} />
        <CustomHandle type="source" position={Position.Right} id="b" isConnectable={props.isConnectable} />
        <CustomHandle type="target" position={Position.Left} id="a" isConnectable={props.isConnectable} />
      </>
    </NodeRoot>
  )
}

export default React.memo(Node)
