import useAuth from 'hooks/useAuth'
import useGlobal from 'hooks/useGlobal'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'

import { ErrorFallback } from 'components/Error/ErrorFallback'
import Header from 'components/Sections/Header'
import SideBar from 'components/Sections/Sidebar'

const Container = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
`

const Content = styled.div``

const RightColumn = styled.div`
  width: 1024px;
`

const LeftColumn = styled.div`
  margin: 1rem;
  width: 10rem;
`

type Props = {
  children?: React.ReactNode
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
  const { user } = useAuth()
  const { todos } = useGlobal()

  if (!user) {
    return <>User not found</>
  }

  return (
    <Container>
      <LeftColumn>
        <SideBar user={user} />
      </LeftColumn>
      <RightColumn>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Header user={user} />
          <Content>{children}</Content>
        </ErrorBoundary>
      </RightColumn>
    </Container>
  )
}

export default DefaultLayout
