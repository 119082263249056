import { format } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IFlowWithLastRun } from 'types'
import { parseAsUTc } from 'utils/date'

const Root = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  &:hover {
    background-color: var(--color-slate-100);
  }
  padding: 1rem;
`
const Tile = styled.div``
const LastRunData = styled.div`
  > span {
    margin-left: 1rem;
  }
`

interface Props {
  flow: IFlowWithLastRun
}

const FlowRow: React.FC<Props> = ({ flow }) => {
  const formatting = 'do MMMM yyyy HH:mm'
  let date = 'Not yet run'

  if (flow.lastFlowRun) {
    date = format(parseAsUTc(flow.lastFlowRun.createdAt), formatting)
  }

  return (
    <Root>
      <Tile>
        <Link to={`/flow/edit/${flow.publicId}`}>
          {' '}
          {flow.name} ({flow.nodes.length} nodes)
        </Link>
      </Tile>
      <LastRunData>
        <span>Last run: {date}</span>
        <span>Run status: {flow.lastFlowRun ? flow.lastFlowRun.status : 'N/A'}</span>
      </LastRunData>
    </Root>
  )
}

export default FlowRow
