import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Root = styled.div`
  background-color: #fff;
  margin-bottom: 1rem;
  border: 1px solid var(--color-gray-200);
`

interface MenuItemProps {
  selected: boolean
}
const MenuItem = styled.div<MenuItemProps>`
  margin-right: 1rem;
  display: inline-block;
  font-weight: ${(props) => (props.selected ? '600' : 'initial')};
  background-color: ${(props) => (props.selected ? 'var(--color-gray-100)' : 'initial')};
  padding: 1rem;

  a {
    text-decoration: none;
  }

  a:visited {
    color: var(--color-blue-600);
  }
`

const menuItems = [
  {
    label: 'Users',
    path: '/organisation/users'
  },
  {
    label: 'Teams',
    path: '/organisation/teams'
  }
]

type MenuItem = (typeof menuItems)[0]

const isCurrentRoute = (item: MenuItem, path: string) => {
  if (path.indexOf(item.path) == 0) {
    return true
  }

  return false
}

const SettingsHeader = () => {
  const location = useLocation()

  return (
    <Root>
      {menuItems.map((item) => (
        <MenuItem key={item.path} selected={isCurrentRoute(item, location.pathname)}>
          <Link to={item.path}>{item.label}</Link>
        </MenuItem>
      ))}
    </Root>
  )
}

export default SettingsHeader
