import { useQuery } from '@tanstack/react-query'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { IMember } from 'types'

import Loading from 'components/Loading/Loading'
import { UserRoleFormValues } from 'components/Permissions/UserRoleForm'
import { Box, Content, Header, PrimaryLinkButton, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

import SettingsHeader from '../SettingsHeader'
import UsersList from './UsersList'

const Root = styled.div``

const Users = () => {
  const { user } = useAuth()

  const members = useQuery(['members'], () => api.getOrganisationUsers())

  const handleChangeMemberRole = async (member: IMember, values: UserRoleFormValues) => {
    try {
      await api.patchOrganisationMember(member.publicId, values)
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      }
      console.error(e)
    }
  }

  return (
    <Root>
      <SettingsHeader />
      <Box>
        <Header>
          <Title>Users</Title>
          <PrimaryLinkButton to={`/organisation/users/invites`}>Invite</PrimaryLinkButton>
        </Header>
        <Content padding={false}>
          {members.isSuccess ? (
            <UsersList user={user!} members={members.data} onChangeRole={handleChangeMemberRole} />
          ) : (
            <Loading />
          )}
        </Content>
      </Box>
    </Root>
  )
}

export default Users
