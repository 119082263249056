import React from 'react'
import { IConnection, IFlowNode, IModelWithId, INodeItemSchema, IResultSet } from 'types'

import { renderFields } from './renderFields'

interface Props {
  uiSchema: INodeItemSchema[]
  connections: IConnection[]
  values: Record<string, unknown>
  node: IFlowNode
  onRefreshConnection: (connection: IModelWithId) => Promise<void>
  inputs: IResultSet[]
}

const FieldsContainer: React.FC<Props> = ({ uiSchema, connections, values, node, onRefreshConnection, inputs }) => {
  const [fields, setFields] = React.useState<JSX.Element[]>([])

  React.useEffect(() => {
    renderFields(uiSchema, values, connections, node, onRefreshConnection, inputs).then((renderedFields) =>
      setFields(renderedFields)
    )
  }, [values, connections, uiSchema, inputs])

  return <>{fields}</>
}

export default FieldsContainer
