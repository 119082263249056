import React, { DragEvent, useMemo } from 'react'
import styled from 'styled-components'
import { INodeType, PaginatedResults } from 'types'

import NodeTypeCategory from './Category'

const Root = styled.div`
  margin-top: 1rem;
`
const Row = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

interface Props {
  paginatedList: PaginatedResults<INodeType>
  onDrag: (event: DragEvent<HTMLDivElement>, nodeType: string) => void
  onDragEnd: () => void
  query: string
}

const ContentList: React.FC<Props> = ({ paginatedList, onDrag, onDragEnd, query }) => {
  const filteredByQuery = useMemo(() => {
    return paginatedList.items
      .filter((it) => (query.length > 0 ? it.name.toLowerCase().includes(query.toLowerCase()) : true))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }, [query, paginatedList.items])

  const sources = useMemo(() => {
    return filteredByQuery.filter((it) => it.category == 'source')
  }, [filteredByQuery])

  const destination = useMemo(() => {
    return filteredByQuery.filter((it) => it.category == 'destination')
  }, [filteredByQuery])

  const triggers = useMemo(() => {
    return filteredByQuery.filter((it) => it.category == 'trigger')
  }, [filteredByQuery])

  const transforms = useMemo(() => {
    return filteredByQuery.filter((it) => it.category == 'transform')
  }, [filteredByQuery])

  return (
    <Root>
      <Row>
        <NodeTypeCategory name="Triggers" nodeTypes={triggers} onDrag={onDrag} onDragEnd={onDragEnd} />
      </Row>
      <Row>
        <NodeTypeCategory name="Sources" nodeTypes={sources} onDrag={onDrag} onDragEnd={onDragEnd} />
        <NodeTypeCategory name="Destinations" nodeTypes={destination} onDrag={onDrag} onDragEnd={onDragEnd} />
      </Row>
      <Row>
        <NodeTypeCategory name="Transforms" nodeTypes={transforms} onDrag={onDrag} onDragEnd={onDragEnd} />
      </Row>
    </Root>
  )
}

export default ContentList
