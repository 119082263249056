import React from 'react'
import styled from 'styled-components'
import { IMember, IUser } from 'types'

import UserRoleForm, { UserRoleFormValues } from 'components/Permissions/UserRoleForm'

const Root = styled.div``
const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0.5rem 1rem;
  background-color: var(--color-blue-50);
`

const Row = styled.div`
  padding: 10px 16px;
  border: 1px solid var(--color-gray-100);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;

  > div {
  }
`
const Name = styled.div``
const Email = styled.div``
const Role = styled.div``

const Actions = styled.div``

interface Props {
  user: IUser
  members: IMember[]
  onChangeRole: (member: IMember, value: UserRoleFormValues) => void
}

const UsersList: React.FC<Props> = ({ user, members, onChangeRole }) => {
  return (
    <Root>
      <Header>
        <Name>Name</Name>
        <Email>Email</Email>
        <Role>Role</Role>
      </Header>
      {members.map((member) => (
        <Row key={member.publicId}>
          <Name>{member.user.name}</Name>
          <Email>{member.user.emailAddress}</Email>
          <Role>
            <UserRoleForm member={member} onSubmit={(values) => onChangeRole(member, values)} />
          </Role>
          <Actions>{member.user.publicId !== user.publicId ? <div></div> : ''}</Actions>
        </Row>
      ))}
    </Root>
  )
}

export default UsersList
