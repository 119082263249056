import { FormikHelpers } from 'formik'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { toast } from 'react-toastify'
import { apiErrorsToFormikErrors } from 'utils/form'

import Loading from 'components/Loading/Loading'
import ProfileForm, { ProfileFormValues } from 'components/ProfileForm/ProfileForm'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Profile = () => {
  const { user, setUser } = useAuth()

  const handleSubmit = async (values: ProfileFormValues, { setErrors }: FormikHelpers<ProfileFormValues>) => {
    try {
      const response = await api.updateUser(values)
      setUser(response)
      toast('Profile updated', { type: 'success' })
    } catch (e) {
      if (e instanceof APIError) {
        setErrors(apiErrorsToFormikErrors(e))
        toast(e.detail, { type: 'error' })
      }
    }
  }
  if (!user) {
    return <Loading />
  }

  return (
    <Box>
      <Header>
        <Title>Your profile</Title>
      </Header>
      <Content>
        <ProfileForm user={user} onSubmit={handleSubmit} />
      </Content>
    </Box>
  )
}

export default Profile
