import useAuth from 'hooks/useAuth'
import useGlobal from 'hooks/useGlobal'
import React, { ReactElement } from 'react'

import * as api from 'services/api'
import { APIError } from 'services/transport'

interface Props {
  children: ReactElement
}

const ReadyGuard: React.FC<Props> = ({ children }) => {
  const [ready, setReady] = React.useState(false)
  const { setSubscription, setTodos, setOrganisation, setOrganisationMember } = useGlobal()
  const { logout } = useAuth()

  const fetchUser = async () => {
    try {
      const response = await api.getWorld()
      setSubscription(response.subscription)
      setTodos(response.todos)
      setOrganisation(response.organisation)
      setOrganisationMember(response.member)
    } catch (e) {
      if (e instanceof APIError) {
        if (e.statusCode == 401) {
          logout()
        }
        return
      }
    }
    setReady(true)
  }

  React.useEffect(() => {
    fetchUser()
  }, [])

  if (!ready) {
    return <p>Loading</p>
  }

  return children
}

export default ReadyGuard
