import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { IIntegration } from 'types'
import * as Yup from 'yup'

import Field from 'components/Form/Field'
import { Button } from 'components/Theme/Styles'

interface Props {
  integration: IIntegration
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void
}

export type FormValues = {
  accountName: string
  apiKey: string
}

const ApiKeyForm: React.FC<Props> = ({ onSubmit, integration }) => {
  // form default values
  const defaultValues = React.useMemo(() => {
    const baseDefaultValues = {
      accountName: '',
      apiKey: ''
    }
    const setupParamsDefaultValues = integration.setupParams?.reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: ''
      }
    }, {})

    return {
      ...baseDefaultValues,
      setupParams: setupParamsDefaultValues
    }
  }, [integration])

  // validation schema
  const validationSchema = React.useMemo(() => {
    const baseValidationSchema = {
      accountName: Yup.string().required(),
      apiKey: Yup.string().required()
    }
    const setupParamsValidation = integration.setupParams?.reduce((prev, current) => {
      return {
        ...prev,
        [current.name]: Yup.string().required()
      }
    }, {})
    return Yup.object().shape({
      ...baseValidationSchema,
      setupParams: Yup.object().shape(setupParamsValidation ? setupParamsValidation : {})
    })
  }, [])

  return (
    <Formik<FormValues> validationSchema={validationSchema} initialValues={defaultValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form className="space-y-2">
          <div>
            <label>Account name</label>
            <Field name="accountName" type="text" />
          </div>
          <div>
            <label>Key</label>
            <Field name="apiKey" type="text" />
          </div>
          {integration.setupParams ? (
            <div>
              {integration.setupParams?.map((it) => (
                <div key={it.name}>
                  <label>{it.label}</label>
                  <Field type={it.type} name={`setupParams.${it.name}`} />
                </div>
              ))}
            </div>
          ) : null}
          <div>
            <Button type="submit" disabled={isSubmitting}>
              Update
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ApiKeyForm
