import { faPlay, faTrash } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import useGraph, { GraphProvider } from 'hooks/useGraph'
import useGraphV2 from 'hooks/useGraphV2'
import React, { DragEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useWebSocket from 'react-use-websocket'
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  EdgeChange,
  NodeChange,
  Position,
  Edge as ReactFlowEdge,
  ReactFlowInstance,
  Node as ReactFlowNode,
  ReactFlowProvider,
  useEdgesState,
  useNodesState
} from 'reactflow'
import 'reactflow/dist/style.css'
import styled, { createGlobalStyle } from 'styled-components'
import { IFlow, IFlowNode, IWebsocketPayload } from 'types'

import ConfirmDelete from 'components/Button/ConfirmDelete'
import Loading from 'components/Loading/Loading'
import ModalRoot from 'components/Modal/ModalRoot'
import Node from 'components/Node/Node'
import NodeTypePicker from 'components/NodeTypePicker/NodeTypePicker'
import { Box, Button, Content, Header, LinkButton, Title } from 'components/Theme/Styles'

import NodeEditor from 'containers/Node/Editor'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  margin: 1rem;
`
const FlowContainer = styled.div`
  width: 100%;
  height: 800px;
  border: 1px solid var(--color-slate-200);
  margin-bottom: 2rem;
`
const Controls = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const FlowStyles = createGlobalStyle`
  .react-flow__edge-path {
    stroke: var(--color-slate-300);
    stroke-width: 3;
    fill: none;
  }
`

type Params = {
  id: string
}

const transformToNodes = (flow: IFlow): ReactFlowNode[] => {
  return flow.nodes.map((node) => {
    return {
      id: node.publicId,
      position: {
        x: node.left,
        y: node.top
      },
      data: node,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      type: 'custom'
    }
  })
}

const transformToEdges = (flow: IFlow): ReactFlowEdge[] => {
  return flow.edges.map((edge) => {
    return {
      id: edge.publicId,
      source: edge.fromNode.publicId,
      target: edge.toNode.publicId,
      type: 'smoothstep'
    }
  })
}

const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL

const FlowEdit = () => {
  const { id } = useParams<Params>() as Params
  const { activeNode, setActiveNode } = useGraph()
  const v2ProcessMessage = useGraphV2((s) => s.processMessage)

  const { lastJsonMessage } = useWebSocket<IWebsocketPayload>(`${wsBaseUrl}/flow/${id}/updates/`, {
    retryOnError: true,
    shouldReconnect() {
      return true
    }
  })

  const [nodes, setNodes, onNodesChange] = useNodesState<IFlowNode>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
  const [currentNodeModal, setCurrentNodeModal] = useState<ReactElement>()
  const [nodeTypes] = useState({
    custom: Node
  })

  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const flowQuery = useQuery(['flow', id], () => api.getFlow(id), {
    onSuccess: (data) => {
      setNodes(transformToNodes(data))
      setEdges(transformToEdges(data))
    }
  })
  const nodeTypesQuery = useQuery(['node-types'], () => api.getNodeTypes())

  useEffect(() => {
    if (lastJsonMessage !== null) {
      v2ProcessMessage(lastJsonMessage)
    }
  }, [lastJsonMessage])

  // Called when a node is joined/connected to another node
  const onConnect = useCallback(
    async (params: Connection) => {
      if (params.source && params.target) {
        const source = params.source
        const target = params.target
        const edge = await api.createNodeEdge(id, source, target)
        setEdges((edges) =>
          addEdge(
            {
              id: edge.publicId,
              source: source,
              target: target,
              type: 'smoothstep'
            },
            edges
          )
        )
      } else {
        setEdges((eds) => addEdge(params, eds))
      }
    },
    [setEdges]
  )

  // Called when a node is moved on the screen
  const handleNodeChange = async (changes: NodeChange[]) => {
    onNodesChange(changes)

    for (const change of changes) {
      if (change.type === 'position' && change.dragging === false) {
        const node = nodes.find((it) => it.id == change.id) as ReactFlowNode<IFlowNode>
        // only change pos it the values are different
        if (node.position.x !== node.data.left || node.position.y !== node.data.top) {
          await api.patchNode(node.id, {
            left: node.position.x,
            top: node.position.y
          })
        }
      }
    }
  }

  const handleDoubleClick = (evt: React.MouseEvent, node: ReactFlowNode) => {
    setActiveNode({
      show: true,
      nodeId: node.id
    })
  }

  const handleCloseModal = () => {
    setActiveNode({
      show: false
    })
  }

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const handleDrop = useCallback(
    async (event: DragEvent) => {
      event.preventDefault()

      if (!reactFlowWrapper.current || !reactFlowInstance) {
        return
      }

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const nodeKind = event.dataTransfer.getData('application/tetra')

      // check if the dropped element is valid
      if (typeof nodeKind === 'undefined' || !nodeKind) {
        return
      }

      if (!nodeTypesQuery.data) {
        console.error('Node types query not ready yet')
        return
      }
      const nodeType = nodeTypesQuery.data.items.find((it) => it.kind == nodeKind)
      if (!nodeType) {
        console.error(`Could not find node type: ${nodeKind}`)
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      })

      const node = await api.createNode(id, {
        name: nodeType.name,
        kind: nodeKind,
        left: position.x,
        top: position.y
      })

      const newNode = {
        id: node.publicId,
        type: 'custom',
        position,
        data: node
      }

      setNodes((nds) => nds.concat(newNode))
    },
    [reactFlowInstance]
  )

  // Called when an edge is deleted
  const handleEdgesChange = async (changes: EdgeChange[]) => {
    for (const change of changes) {
      if (change.type === 'remove') {
        await api.deleteEdge(change.id)
      }
    }

    onEdgesChange(changes)
  }

  // The main Run button on the top right corner of the page
  const handleRun = useCallback(async () => {
    try {
      await api.runFlow(id)
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      } else {
        toast('There was an error running this node', { type: 'error' })
      }
      console.error(e)
    }
  }, [id])

  const handleDelete = async () => {
    try {
      api.deleteWorkflow(flowQuery.data!.publicId)
      navigate('/')
    } catch (e) {
      toast('There was an error archiving this flow', { type: 'error' })
    }
  }

  useEffect(() => {
    const setupCurrentNodeModal = async () => {
      if (!activeNode.nodeId) {
        setCurrentNodeModal(undefined)
        return
      }

      const node = nodes.find((it) => it.id == activeNode.nodeId)
      if (!node) {
        return
      }

      setCurrentNodeModal(<NodeEditor node={node.data} />)
    }
    setupCurrentNodeModal()
  }, [activeNode.nodeId, nodes])

  return (
    <Root>
      <FlowStyles />
      <Box>
        <Header>
          <Title>Flow editor</Title>
          <Controls>
            <a href="#"></a>
            <ConfirmDelete onConfirm={handleDelete}>
              <FontAwesomeIcon icon={faTrash} /> Archive workflow
            </ConfirmDelete>
            <Button onClick={handleRun} primary={true}>
              <FontAwesomeIcon icon={faPlay} /> Run
            </Button>
            <LinkButton to={`/`}>Back</LinkButton>
          </Controls>
        </Header>
        <Content>
          {flowQuery.isLoading || nodeTypesQuery.isLoading || Object.keys(nodeTypes).length === 0 ? (
            <Loading />
          ) : (
            <>
              <FlowContainer>
                <ReactFlow
                  ref={reactFlowWrapper}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={handleNodeChange}
                  onEdgesChange={handleEdgesChange}
                  onConnect={onConnect}
                  proOptions={{
                    hideAttribution: true
                  }}
                  nodeTypes={nodeTypes}
                  onNodeDoubleClick={handleDoubleClick}
                  onInit={setReactFlowInstance}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  maxZoom={1}
                  minZoom={1}
                >
                  <Background />
                </ReactFlow>
              </FlowContainer>
              <NodeTypePicker />
            </>
          )}
        </Content>
      </Box>
      {currentNodeModal && <ModalRoot modal={currentNodeModal} onClickOutside={handleCloseModal} />}
    </Root>
  )
}

const WrappedFlowEdit = () => {
  return (
    <ReactFlowProvider>
      <GraphProvider>
        <FlowEdit />
      </GraphProvider>
    </ReactFlowProvider>
  )
}

export default WrappedFlowEdit
