import React, { createContext, useContext, useRef } from 'react'
import { IConnection, INodeItemSchema, IResultSet } from 'types'
import { createStore, useStore } from 'zustand'

// ref: https://github.com/pmndrs/zustand/blob/main/docs/guides/initialize-state-with-props.md

interface FormState {
  uiSchema: INodeItemSchema[]
  connections: IConnection[]
  inputs: IResultSet[]
}

type ProviderProps = React.PropsWithChildren & Partial<FormState>
type NodeFormStore = ReturnType<typeof createNodeFormStore>

const createNodeFormStore = (initialProps?: Partial<FormState>) => {
  return createStore<FormState>()(() => ({
    inputs: [],
    uiSchema: [],
    connections: [],
    ...initialProps
  }))
}

export const NodeFormContext = createContext<NodeFormStore | null>(null)

export const NodeFormProvider: React.FC<ProviderProps> = ({ children, ...props }) => {
  const storeRef = useRef<NodeFormStore>()

  if (!storeRef.current) {
    storeRef.current = createNodeFormStore(props)
  }

  return <NodeFormContext.Provider value={storeRef.current}>{children}</NodeFormContext.Provider>
}

export function useNodeFormContext<T>(
  selector: (state: FormState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(NodeFormContext)
  if (!store) {
    throw new Error('Missing NodeFormContext.Provider')
  }

  return useStore(store, selector, equalityFn)
}
