import { UserRole } from 'consts'

export const slugify = (text: string): string => {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
}

export const roleToText = (role: UserRole): string => {
  switch (role) {
    case UserRole.ADMIN:
      return 'Admin'
    case UserRole.VIEWER:
      return 'Viewer'
    default:
      return 'Unknown role'
  }
}
