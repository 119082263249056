import { toast } from 'react-toastify'
import { IWebsocketPayload, RunStatus, WebsocketEventType } from 'types'
import { create } from 'zustand'

interface Store {
  nodeChanges: Record<string, RunStatus[] | undefined>
  processMessage: (payload: IWebsocketPayload) => void
  isRunning: (nodeId: string) => boolean
}

const useGraphV2 = create<Store>((set, get) => ({
  nodeChanges: {},
  processMessage: (payload) => {
    const nodeId = payload.update.node
    const currentChanges = get().nodeChanges[nodeId] ?? []

    if (payload.update.event === WebsocketEventType.NODE_RUN_STATUS) {
      set((state) => ({
        nodeChanges: {
          ...state.nodeChanges,
          [nodeId]: [...currentChanges, payload.update.status]
        }
      }))
    }

    if (payload.update.event === WebsocketEventType.FLOW_RUN_STATUS) {
      switch (payload.update.status) {
        case RunStatus.ERROR:
          toast(payload.update.error, { type: 'error' })
          break
        case RunStatus.SUCCESS:
          toast('Flow has finished running', { type: 'success' })
          break
      }
    }
  },
  isRunning: (nodeId) => {
    const changes = get().nodeChanges[nodeId] ?? []
    if (changes.length === 0) {
      return false
    }

    const lastChange = changes.at(-1)
    return lastChange === RunStatus.RUNNING ? true : false
  }
}))

export default useGraphV2
