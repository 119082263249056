import React from 'react'
import { Connection, Handle, HandleProps, useEdges } from 'reactflow'

const CustomHandle: React.FC<HandleProps> = (props) => {
  const edges = useEdges()

  const isValidConnection = (connection: Connection): boolean => {
    const edge = edges.find((c) => c.target === connection.target && c.source === connection.source)
    if (edge) {
      return false
    }

    return true
  }

  return <Handle style={{ top: '50%' }} isValidConnection={isValidConnection} {...props} />
}

export default CustomHandle
