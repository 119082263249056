import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Content } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  width: 30rem;
  margin: 10rem auto;
`
const FooterMessage = styled.div`
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-gray-200);
  padding: 1rem;
  border-radius: 0.4rem;

  > a {
    text-decoration: none;
  }
`

const Verify: React.FC = () => {
  const [verified, setVerified] = React.useState(false)
  const [error, setError] = React.useState('')

  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const email = params.get('email')

  const verifyAccount = async () => {
    if (!code || !email) {
      return
    }

    try {
      await api.verifyAccount(email, code)
      setVerified(true)
    } catch (e) {
      if (e instanceof APIError) {
        setError(e.detail)
      }
    }
  }

  React.useEffect(() => {
    verifyAccount()
  }, [])

  return (
    <Root>
      <Box>
        <Content data-testid="login-page">
          {verified && 'You account has been verified, please login'}
          {error != '' && error}
          {error == '' && !verified && 'Verifying your account'}
        </Content>
      </Box>
      <FooterMessage>
        <Link to={'/login'}>Login</Link>
        <Link to={'/verify/send'}>Resend verification code</Link>
      </FooterMessage>
    </Root>
  )
}

export default Verify
