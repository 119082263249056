import { UserRole } from 'consts'
import useGlobal from 'hooks/useGlobal'
import React from 'react'

interface Props {
  action: ActionKeys
  children: React.ReactNode
}

const permissions = {
  'manage-settings': [UserRole.ADMIN],
  'manage-billing': [UserRole.ADMIN]
}

type Actions = typeof permissions
type ActionKeys = keyof Actions

const RenderIfCan: React.FC<Props> = ({ action, children }) => {
  const { organisationMember } = useGlobal()

  if (!organisationMember) {
    return null
  }

  if (!permissions[action].includes(organisationMember.role)) {
    return null
  }

  return <>{children}</>
}

export default RenderIfCan
