import { useQuery } from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { IInvite } from 'types'
import { apiErrorsToFormikErrors } from 'utils/form'

import InviteForm, { FormValues } from 'components/Invite/InviteForm'
import SentInvite from 'components/Invite/SentInvite'
import Loading from 'components/Loading/Loading'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

import SettingsHeader from '../SettingsHeader'

const Root = styled.div``
const InviteList = styled.div`
  margin-bottom: 1rem;
`
const InvitesHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0.5rem 1rem;
  background-color: var(--color-gray-50);
  margin-top: 0.5rem;
`
const EmptyState = styled.div`
  margin-bottom: 1rem;
  background-color: var(--color-gray-50);
  padding: 1rem;
  text-align: center;
  color: var(--color-gray-400);
`

const Invites = () => {
  const invites = useQuery(['invites'], () => api.getInvites())

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await api.createInvite(values)
      invites.refetch()
      toast('Invite sent', { type: 'success' })
    } catch (e) {
      if (e instanceof APIError) {
        helpers.setErrors(apiErrorsToFormikErrors(e))
        toast(e.detail, { type: 'error' })
      }
      console.error(e)
    }
  }

  const handleDelete = async (invite: IInvite) => {
    try {
      await api.deleteInvite(invite.publicId)
      toast('Invite has been deleted', { type: 'warning' })
      invites.refetch()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Root>
      <SettingsHeader />
      <Box>
        <Header>
          <Title>Invite your team</Title>
        </Header>
        <Content>
          {invites.isFetching && <Loading />}
          {invites.isFetched && invites.data && invites.data.length > 0 ? (
            <>
              <InviteList>
                <p>Pending invites</p>
                <InvitesHeader>
                  <div>Email</div>
                  <div>Status</div>
                  <div>Sent at</div>
                </InvitesHeader>
                {invites.data.map((invite) => (
                  <SentInvite key={invite.publicId} invite={invite} onDelete={handleDelete} />
                ))}
              </InviteList>
            </>
          ) : (
            <EmptyState>No invites sent</EmptyState>
          )}
          <InviteForm onSubmit={handleSubmit} />
        </Content>
      </Box>
    </Root>
  )
}

export default Invites
