import { faBolt, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { formatRelative } from 'date-fns'
import { FormikHelpers } from 'formik'
import useGraphV2 from 'hooks/useGraphV2'
import { NodeFormProvider } from 'hooks/useNodeFormContext'
import React from 'react'
import { toast } from 'react-toastify'
import { useReactFlow } from 'reactflow'
import styled from 'styled-components'
import { IFlowNode, IModelWithId, INodeItemSchema, INodeResultSetItem, RunStatus } from 'types'
import { parseAsUTc } from 'utils/date'
import { apiErrorsToFormikErrors } from 'utils/form'

import Loading from 'components/Loading/Loading'
import NodeForm, { FormValues } from 'components/Node/NodeForm'
import EmptyTable from 'components/Table/EmptyTable'
import DataGrid, { ColumnProperty } from 'components/Table/Table'
import { Button } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

import { renderCell } from './renderCell'

const Root = styled.div`
  display: flex;
  background-color: #fff;
  height: 100%;
  border-radius: 1rem;
`
const Left = styled.div`
  padding: 1rem;
  border-right: 1px solid var(--color-gray-100);
  width: 400px;
  flex: 1 0 auto;
`
const Right = styled.div`
  padding: 1rem;
  overflow: hidden;
`
const TableContainer = styled.div`
  overflow: scroll;
  max-height: 600px;
`
const Controls = styled.div`
  margin-bottom: 1rem;
  justify-content: right;
  display: flex;
  justify-content: space-between;
`
const TableStats = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  display: flex;
  gap: 1rem;

  > div {
    background-color: var(--color-slate-100);
    padding: 0.25rem 0.5rem;
    border-radius: 0.8rem;
  }
`

const SchemaError = styled.div`
  padding: 1rem;
  background-color: var(--color-red-100);
  margin-top: 1rem;
`

interface Props {
  node: IFlowNode
}

const NodeEditor: React.FC<Props> = ({ node }) => {
  // All data queries
  const dataQuery = useQuery(['node-data', node.publicId], () => api.getNodeResultSet(node.publicId))
  const nodeQuery = useQuery(['node', node.publicId], () => api.getNode(node.publicId), {
    onSuccess: (data) => {
      handleNodeUpdate(data)
    }
  })
  const schemaQuery = useQuery<INodeItemSchema[], APIError>({
    queryKey: ['schema', node.publicId],
    queryFn: () => api.getNodeSchema(node.publicId),
    retry: false
  })

  const connectionsQuery = useQuery(['connections', node.publicId], () => api.getConnections({ kind: node.kind }))
  const nodeInputsQuery = useQuery(['node-inputs', node.publicId], () => api.getNodeInputs(node.publicId))

  const nodeChanges = useGraphV2((s) => s.nodeChanges)
  const [isRunning, setRunning] = React.useState(false)
  const { setNodes } = useReactFlow()

  // Called when form is submitted
  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      const response = await api.patchNode(node.publicId, values)
      handleNodeUpdate(response)
      helpers.resetForm({ values })
      schemaQuery.refetch()
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
        helpers.setErrors(apiErrorsToFormikErrors(e))
      }
      return
    }
  }

  // Called when connection is refreshed
  const handleConnectionRefresh = async (connection: IModelWithId) => {
    try {
      await api.refreshConnection(connection.publicId)
      schemaQuery.refetch()
      connectionsQuery.refetch()
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      }
    }
  }

  // When "Run" within Modal is clicked
  const handleRun = async () => {
    try {
      await api.runNode(node.publicId)
    } catch (error) {
      if (error instanceof APIError) {
        toast(error.detail, { type: 'error' })
      }
    }
  }

  // Called after changes are saved
  const handleNodeUpdate = React.useCallback(
    (node: IFlowNode) => {
      setNodes((nodes) => {
        return nodes.map((it) => {
          if (it.id === node.publicId) {
            return {
              ...it,
              data: node
            }
          } else {
            return it
          }
        })
      })
    },
    [node]
  )

  React.useEffect(() => {
    const changes = nodeChanges[node.publicId]
    switch (changes?.at(-1)) {
      case RunStatus.SUCCESS:
        dataQuery.refetch()
        schemaQuery.refetch()
        nodeQuery.refetch()
        setRunning(false)
        break
      case RunStatus.RUNNING:
        setRunning(true)
        break
      case RunStatus.ERROR:
        setRunning(false)
        break
    }
  }, [nodeChanges[node.publicId]])

  // Table columns
  const columns: ColumnProperty<INodeResultSetItem>[] = React.useMemo(() => {
    if (!dataQuery.data?.columns) {
      return []
    }

    return dataQuery.data.columns.map((it) => ({
      name: it,
      render: (row) => renderCell(row.parsedData[it]),
      style: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        padding: '0.5rem 2rem',
        borderRight: '1px solid var(--color-gray-100'
      }
    }))
  }, [dataQuery.data?.columns])

  return (
    <Root>
      <Left>
        <h2>{node.name}</h2>
        {schemaQuery.isSuccess && nodeInputsQuery.isSuccess && (
          <NodeFormProvider
            uiSchema={schemaQuery.data}
            inputs={nodeInputsQuery.data?.items}
            connections={connectionsQuery.data}
          >
            <NodeForm
              connections={connectionsQuery.data ?? []}
              node={node}
              onSubmit={handleSubmit}
              uiSchema={schemaQuery.data}
              onRefreshConnection={handleConnectionRefresh}
              inputs={nodeInputsQuery.data?.items ?? []}
            />
          </NodeFormProvider>
        )}
        {schemaQuery.isError && <SchemaError>{schemaQuery.error.message}</SchemaError>}
        {((schemaQuery.isLoading && !schemaQuery.isError) || nodeInputsQuery.isLoading) && (
          <Loading text="Loading..." />
        )}
      </Left>
      <Right>
        <Controls>
          <div>
            Last run{' '}
            {dataQuery.data && dataQuery.data.createdAt
              ? formatRelative(parseAsUTc(dataQuery.data.createdAt), new Date())
              : 'Not run'}
          </div>
          <Button primary={true} onClick={handleRun} disabled={isRunning}>
            {isRunning ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin={true} /> Running
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faBolt} fixedWidth /> Run
              </>
            )}
          </Button>
        </Controls>
        {dataQuery.isLoading ? (
          <Loading text="Loading data..." />
        ) : (
          <>
            {!dataQuery.data || dataQuery.data.total === 0 ? (
              <EmptyTable message="No data" />
            ) : (
              <div>
                <TableStats>
                  <div>Total records: {dataQuery.isFetched ? <>{dataQuery.data?.total}</> : '...'}</div>
                  <div>Columns: {dataQuery.data.columns.length}</div>
                </TableStats>

                <TableContainer>
                  <DataGrid
                    rowKey={'publicId'}
                    columns={columns}
                    data={dataQuery.data ? dataQuery.data.objects : []}
                    headerCellStyle={{ padding: '0.5rem 2rem' }}
                  />
                </TableContainer>
              </div>
            )}
          </>
        )}
      </Right>
    </Root>
  )
}

export default NodeEditor
