import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'hooks/useAuth'
import { GlobalProvider } from 'hooks/useGlobal'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import ModalProvider from 'components/Modal/ModalProvider'

import AdminIndex from 'containers/Admin/Index'
import Dashboard from 'containers/Dashboard/Dashboard'
import CreateFlow from 'containers/Flow/Create'
import FlowEdit from 'containers/Flow/Edit'
import FlowRunIndex from 'containers/FlowRun/Index'
import AdminGuard from 'containers/Guard/AdminGuard'
import AuthGuard from 'containers/Guard/AuthGuard'
import ReadyGuard from 'containers/Guard/ReadyGuard'
import CreateApiKey from 'containers/Integration/CreateApiKey'
import IntegrationIndex from 'containers/Integration/Index'
import DefaultLayout from 'containers/Layout/DefaultLayout'
import Login from 'containers/Login/Login'
import OAuthComplete from 'containers/OAuth/Complete'
import CreateTeam from 'containers/Organisation/Teams/CreateTeam'
import ShowTeam from 'containers/Organisation/Teams/ShowTeam'
import Teams from 'containers/Organisation/Teams/Teams'
import Invites from 'containers/Organisation/Users/Invites'
// organisation settings
import Users from 'containers/Organisation/Users/Users'
import Register from 'containers/Register/Register'
import RegisterSuccess from 'containers/Register/Success'
import Billing from 'containers/Settings/Billing'
import Profile from 'containers/Settings/Profile'
import VerifySendCode from 'containers/Verify/Send'
// account verify
import Verify from 'containers/Verify/Verify'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const AdminRoutes = () => (
  <AdminGuard>
    <Routes>
      <Route index element={<AdminIndex />} />
    </Routes>
  </AdminGuard>
)

const DefaultLayoutRoutes = () => (
  <DefaultLayout>
    <Routes>
      <Route path="/" element={<Dashboard />} />

      <Route path="/flow/create" element={<CreateFlow />} />
      <Route path="/flow-runs" element={<FlowRunIndex />} />

      <Route path="/organisation/users" element={<Users />} />
      <Route path="/organisation/users/invites" element={<Invites />} />
      <Route path="/organisation/teams" element={<Teams />} />
      <Route path="/organisation/teams/create" element={<CreateTeam />} />
      <Route path="/organisation/teams/:id" element={<ShowTeam />} />

      <Route path="/integrations" element={<IntegrationIndex />} />
      <Route path="/integrations/create/api-key" element={<CreateApiKey />} />

      <Route path="/profile" element={<Profile />} />
      <Route path="/billing" element={<Billing />} />

      <Route path="/oauth/complete" element={<OAuthComplete />} />

      <Route path="/admin/*" element={<AdminRoutes />} />
    </Routes>
  </DefaultLayout>
)

const AuthenticatedRoutes = () => (
  <AuthGuard>
    <ReadyGuard>
      <ModalProvider>
        <Routes>
          <Route path="/flow/edit/:id" element={<FlowEdit />} />
          <Route path="/*" element={<DefaultLayoutRoutes />} />
        </Routes>
      </ModalProvider>
    </ReadyGuard>
  </AuthGuard>
)

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={navigator.language}>
        <BrowserRouter>
          <GlobalProvider>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register/success" element={<RegisterSuccess />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/verify/send" element={<VerifySendCode />} />
                <Route path="/*" element={<AuthenticatedRoutes />} />
              </Routes>
            </AuthProvider>
          </GlobalProvider>
        </BrowserRouter>
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <ReactQueryDevtools />
      </IntlProvider>
    </QueryClientProvider>
  )
}

export default App
