import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import FlowForm, { FormValues } from 'components/Flow/Form'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

const Root = styled.div``

const CreateFlow = () => {
  const navigate = useNavigate()
  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await api.createFlow(values)
      navigate(`/flow/edit/${response.publicId}`)
      toast('New flow created', { type: 'success' })
    } catch (e) {
      toast('There was error creating the flow', { type: 'error' })
    }
  }

  return (
    <Root>
      <Box>
        <Header>
          <Title>Flow</Title>
        </Header>
        <Content>
          <FlowForm onSubmit={handleSubmit} />
        </Content>
      </Box>
    </Root>
  )
}

export default CreateFlow
