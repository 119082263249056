import { FormikHelpers } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { apiErrorsToFormikErrors } from 'utils/form'

import { Box, Content } from 'components/Theme/Styles'
import SendCodeForm, { SendCodeFormValues } from 'components/Verify/SendCode'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const Root = styled.div`
  width: 30rem;
  margin: 10rem auto;

  > h2 {
    margin-bottom: 1rem;
  }
`
const FooterMessage = styled.div`
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--color-gray-200);
  padding: 1rem;
  border-radius: 0.4rem;

  > a {
    text-decoration: none;
  }
`

const VerifySendCode: React.FC = () => {
  const handleSubmit = async (values: SendCodeFormValues, { setErrors }: FormikHelpers<SendCodeFormValues>) => {
    try {
      await api.sendVerificationCode(values.emailAddress)
      toast('New verification code has been sent, please check your email account', { type: 'success' })
    } catch (e) {
      if (e instanceof APIError) {
        setErrors(apiErrorsToFormikErrors(e))
      }
    }
  }

  return (
    <Root>
      <h2>Send verification code</h2>
      <Box>
        <Content data-testid="login-page">
          <SendCodeForm onSubmit={handleSubmit} />
        </Content>
      </Box>
      <FooterMessage>
        <Link to={'/register'}>Create a new account</Link>
        <Link to={`/login`}>Login to your account</Link>
      </FooterMessage>
    </Root>
  )
}

export default VerifySendCode
