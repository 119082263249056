import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Content, Header, Title } from 'components/Theme/Styles'

const Root = styled.div``

const AdminIndex = () => {
  return (
    <Root>
      <Box>
        <Header>
          <Title>Admin</Title>
        </Header>
        <Content>
          <ul>
            <li>
              <Link to={`/admin/reports`}>Reports</Link>
            </li>
            <li>
              <Link to={`/admin/entries`}>All food entries</Link>
            </li>
          </ul>
        </Content>
      </Box>
    </Root>
  )
}

export default AdminIndex
