import useAuth from 'hooks/useAuth'
import React, { ReactElement } from 'react'

interface Props {
  children: ReactElement
}

const AdminGuard: React.FC<Props> = ({ children }) => {
  const [redirect, setRedirect] = React.useState(false)
  const { user } = useAuth()

  React.useEffect(() => {
    if (!user) {
      setRedirect(true)
      return
    }
    if (!user.isSuperAdmin) {
      setRedirect(true)
      return
    }
  }, [user])

  if (redirect) {
    return <>Not authorized</>
  }

  return children
}

export default AdminGuard
