import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import ApiKeyForm, { FormValues } from 'components/Integration/ApiKeyForm'
import { Box, Content, Header, Title } from 'components/Theme/Styles'

import * as api from 'services/api'
import { APIError } from 'services/transport'

const CreateApiKey = () => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const params = new URLSearchParams(search)
  const integrationId = params.get('integrationId') as string

  const integrationQuery = useQuery(['integration', integrationId], () => api.getIntegration(integrationId))

  const handleSubmit = async (values: FormValues) => {
    try {
      await api.createConnection(integrationId, {
        integration: {
          publicId: integrationId
        },
        ...values
      })
      navigate(`/integrations`)
    } catch (e) {
      if (e instanceof APIError) {
        toast(e.detail, { type: 'error' })
      }
    }
  }

  return (
    <div>
      <Box>
        <Header>
          <Title>Create API key</Title>
        </Header>
        <Content>
          <p>Creating a new connection for {integrationQuery.data?.name}</p>
          <br />
          {integrationQuery.isSuccess ? (
            <ApiKeyForm integration={integrationQuery.data} onSubmit={handleSubmit} />
          ) : null}
        </Content>
      </Box>
    </div>
  )
}

export default CreateApiKey
