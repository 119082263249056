import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ITeam } from 'types'
import { parseAsUTc } from 'utils/date'

import Loading from 'components/Loading/Loading'
import DataGrid, { ColumnProperty } from 'components/Table/Table'
import { Box, Content, Header, PrimaryLinkButton, Title } from 'components/Theme/Styles'

import * as api from 'services/api'

import SettingsHeader from '../SettingsHeader'

const Root = styled.div``
const EmptyState = styled.div`
  padding: 1rem;
`

const Teams = () => {
  const teams = useQuery(['teams'], () => api.getTeams())
  const navigate = useNavigate()

  const handleRowClick = (row: ITeam) => {
    navigate(`/organisation/teams/${row.publicId}`)
  }

  const columns: ColumnProperty<ITeam>[] = [
    {
      name: 'Name',
      render: (row) => <Link to={`/organisation/teams/${row.publicId}`}>{row.name}</Link>
    },
    {
      name: 'Members',
      render: (row) => row.totalUsers
    },
    {
      name: 'Created',
      render: (row) => {
        const date = parseAsUTc(row.createdAt)
        return <FormattedDate value={date} />
      }
    }
  ]

  return (
    <Root>
      <SettingsHeader />
      <Box>
        <Header>
          <Title>Teams</Title>
          <div>
            <PrimaryLinkButton to={`/organisation/teams/create`}>Add team</PrimaryLinkButton>
          </div>
        </Header>
        <Content padding={false}>
          {teams.isFetching ? (
            <Loading text="Loading teams" />
          ) : (
            <>
              {teams.data && teams.data.length > 0 ? (
                <DataGrid
                  rowKey={'publicId'}
                  data={teams.data ? teams.data : []}
                  columns={columns}
                  onRowClick={handleRowClick}
                />
              ) : (
                <EmptyState>There are no teams</EmptyState>
              )}
            </>
          )}
        </Content>
      </Box>
    </Root>
  )
}

export default Teams
